import { type AutocompleteProps, Autocomplete, Loader } from '@mantine/core'
import { type FC } from 'react'
import { Field, type FieldProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { Search } from 'lucide-react'

type InputAutocompleteProps = AutocompleteProps & {
  name: string
  onChange: (value: string) => void
  isLoading: boolean
  initialValue: any
  required?: boolean
  onItemSubmit?: (value: number) => void
}

const inputClassName =
  'min-h-[2.5rem] rounded-md border-gray/30 bg-white text-black focus:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50'

export const InputAutocompleteField: FC<InputAutocompleteProps> = props => {
  const {
    value,
    className,
    name,
    label,
    required,
    disabled,
    initiallyOpened,
    onChange,
    data,
    isLoading,
    initialValue,
    onItemSubmit,
  } = props

  const { t } = useTranslation(['common'])

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div className={className}>
          <Autocomplete
            {...field}
            name={name}
            label={label}
            value={value}
            data={data}
            onBlur={() => {
              if (!form.values[name]) onChange('')
            }}
            onChange={newValue => {
              form.setFieldValue(name, initialValue)
              onChange(newValue)
            }}
            onItemSubmit={item => {
              form.setFieldValue(name, +item.value)
              onChange(item.label)
              if (onItemSubmit) {
                onItemSubmit(Number(item.value))
              }
            }}
            filter={() => true}
            icon={<Search size={16} stroke='#D3D3D3' />}
            withAsterisk={required}
            disabled={disabled}
            initiallyOpened={initiallyOpened}
            error={meta.touched && meta.error}
            nothingFound={
              isLoading ? (
                <div className='flex w-full items-center flex-col'>
                  <div className='flex w-[1.25rem] h-[1.25rem] justify-center items-center'>
                    <Loader size='xs' color='blue' />
                  </div>
                </div>
              ) : (
                t('common:nothing_found')
              )
            }
            classNames={{
              root: 'leading-4 text-left',
              input: inputClassName,
              label: 'text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2',
              error: 'mt-1',
              description: 'text-black/50',
            }}
          />
        </div>
      )}
    </Field>
  )
}
