import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { errorHandler } from '@/shared/lib/errorHandler'
import { TemplateIn, topliApi } from '@/shared/api/topli/TopliApi'
import {
  messageTemplatesByPageQueryKey,
  messageTemplatesQueryKey,
} from '@/entities/MessageTemplates/api/message-tempates-api'

export const createMessageTemplate = async (state: TemplateIn) => {
  const response = await topliApi.template.create({ item_id: null }, state)
  return response
}

export const useCreateMessageTemplateApi = (): UseMutationResult => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (state: any) => createMessageTemplate(state),
    onSuccess: async () => {
      try {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [messageTemplatesByPageQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [messageTemplatesQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
