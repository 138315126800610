import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'

export const generateTranslationSchema = (
  languageConfig: LanguageTabConfig[],
  t: TFunction,
  withDescription: boolean = true,
) => {
  const commonTranslationSchema = {
    name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
    ...(withDescription && {
      description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
    }),
  }

  const translationSchemas = languageConfig.reduce(
    (acc, { props: { languageCode, required } }) => {
      acc[languageCode] = Yup.object(
        required
          ? {
              ...commonTranslationSchema,
              name: commonTranslationSchema.name.required(t('common:validation_messages.required')),
            }
          : commonTranslationSchema,
      )
      return acc
    },
    {} as Record<string, Yup.ObjectSchema<any>>,
  )

  return Yup.object().shape({
    translations: Yup.object(translationSchemas),
  })
}
