import {
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table'
import { useEventsTable } from './lib/use-events-table'
import { useTranslation } from 'react-i18next'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'

interface Props {
  currentUserId: number
}

export const EventsTable = ({ currentUserId }: Props) => {
  const {
    pagination,
    columns,
    fetchedNotifications,
    isErrorNotifications,
    isFetchingNotifications,
    isLoadingNotifications,
    totalRowCount,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  } = useEventsTable(currentUserId)
  const { i18n } = useTranslation()
  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedNotifications,
    enableStickyHeader: false,
    enableColumnActions: false,
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: totalRowCount,
    manualSorting: true,
    onSortingChange: setSorting,
    enableEditing: false,
    localization,
    enableRowActions: false,
    enableGlobalFilter: true,
    onGlobalFilterChange: handleSearchChange,
    mantineSearchTextInputProps: {
      classNames: {
        root: 'w-full min-w-[calc(100vw-2rem)] lg:min-w-[16rem]',
        input: 'w-full',
      },
      variant: 'filled',
    },
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row items-center justify-between mb-2'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2'>
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
          </div>
        </div>
      )
    },
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      isLoading: isLoadingNotifications,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorNotifications,
      showProgressBars: isFetchingNotifications,
    },
  })

  return <MantineReactTable table={table} />
}
