import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { TemplateNames } from '@/shared/lib/constants'

export const createMessageTemplateFormScheme = (t: TFunction) => {
  return Yup.object({
    name: Yup.mixed()
      .oneOf(Object.values(TemplateNames) as string[], t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    content: Yup.string()
      .min(12, t('common:validation_messages.min', { count: 12 }))
      .required(t('common:validation_messages.required')),
    comment: Yup.string().max(256, t('common:validation_messages.max', { count: 256 })),
  })
}

export const createMessageTemplateFormDefault = {
  name: '',
  comment: '',
  content: '',
}
