import { useEffect, useState } from 'react'
import { EditCompanyFormState } from '../model/types'
import { editCompanyFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { useEditCompanyApi } from '@/features/Companies/EditCompanyForm'
import { notification } from '@/shared/helpers/notificationMessages'
import { convertPhoneNumberToString, hasAiTranslation, matchTranslations } from '@/shared/lib/utils'
import { useCompanyById } from '@/entities/Company'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useTranslation } from 'react-i18next'
import { updateTranslationsForEditing } from '@/shared/lib/updateTranslationsForEditing'
import { useSearchUsersByName } from '@/entities/User/api/user-api'
import { useDebounce } from '@/shared/lib/useDebounce'
import { EditTranslations } from '@/entities/ContentTranslation/model/types'

export const useEditCompanyForm = (onCloseModal: () => void, currentCompanyId: number) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [initialValues, setInitialValues] = useState<EditCompanyFormState>(
    () => editCompanyFormDefault,
  )
  const [originalTranslations, setOriginalTranslations] = useState<EditTranslations | null>(null)
  const { t } = useTranslation(['common'])
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const { data: users, isLoading: isLoadingUsers } = useSearchUsersByName(debouncedSearchValue)

  const {
    data: currentCompany,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
  } = useCompanyById(currentCompanyId)

  const {
    mutateAsync,
    isPending: isLoadingEditCompany,
    isSuccess: isSuccessEditCompany,
  } = useEditCompanyApi()

  const submitForm: FormikConfig<EditCompanyFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values
    const updatedTranslations = updateTranslationsForEditing(translations, originalTranslations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    try {
      await mutateAsync(updatedValues)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('notification_messages.edit_company_error'))
    }
  }

  useEffect(() => {
    if (currentCompany) {
      setInitialValues(() => {
        const {
          id,
          featured,
          only_on_topli,
          translations,
          year,
          email,
          phone,
          address,
          website,
          type,
          responsible,
        } = currentCompany
        const matchedTranslations = matchTranslations(translations, initialValues.translations)
        setSearchValue(responsible?.fullname ?? '')
        setOriginalTranslations({ ...matchedTranslations })
        return {
          id,
          featured,
          only_on_topli,
          translations: matchedTranslations,
          year: year ? new Date(year.toString()) : null,
          email,
          phone: convertPhoneNumberToString(phone),
          address,
          website,
          type,
          responsible_id: responsible.id,
        }
      })
    }
  }, [currentCompany])

  useEffect(() => {
    if (isSuccessEditCompany) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_company_success'),
      )
    }
  }, [isSuccessEditCompany])

  const userListOptions = (users?.data || []).map(user => ({
    label: user.fullname as string,
    value: String(user.id),
  }))

  return {
    initialValues,
    userListOptions,
    isLoadingUsers,
    isLoadingEditCompany,
    isLoadingCompany,
    isErrorCompany,
    submitForm,
    searchValue,
    setSearchValue,
    editCompanyFormDefault,
  }
}
