import { useEffect, useState } from 'react'
import { CreateBuildingFormState } from '../model/types'
import { createBuildingFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useCreateBuildingApi } from '../api/create-building-form-api'
import { ImagableType } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import {
  createAutocompleteOptions,
  getCurrentTranslation,
  hasAiTranslation,
} from '@/shared/lib/utils'
import { initializeTranslationsForCreation } from '@/shared/lib/initializeTranslationsForCreation'
import { useDebounce } from '@/shared/lib/useDebounce'
import {
  useResidentialById,
  useSearchResidentialsByText,
} from '@/entities/Residential/api/residential-api'

type UseCreateBuildingFormProps = {
  onCloseModal: () => void
  selectResidentialId?: number
}

export const useCreateBuildingForm = (props: UseCreateBuildingFormProps) => {
  const { onCloseModal, selectResidentialId } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const { language } = useLanguageStore()

  const { t } = useTranslation(['common'])
  const { data: currentResidential } = useResidentialById(selectResidentialId)

  const [initialValues, setInitialValues] = useState<CreateBuildingFormState>(
    () => createBuildingFormDefault,
  )
  const { mutateAsync, isPending: isLoadingCreateBuilding } = useCreateBuildingApi(
    ImagableType.Building,
  )
  const debouncedSearchValue = useDebounce(searchValue, 500)

  const { data: residentials, isLoading: isLoadingResidentials } = useSearchResidentialsByText(
    language,
    debouncedSearchValue,
  )

  useEffect(() => {
    if (selectResidentialId && currentResidential) {
      setSearchValue(getCurrentTranslation(currentResidential, language, 'name'))
      setInitialValues(prev => ({
        ...prev,
        residential_id: selectResidentialId,
        delivery_date: currentResidential?.delivery_date
          ? new Date(currentResidential?.delivery_date)
          : null,
      }))
    }
  }, [selectResidentialId, currentResidential])

  const submitForm: FormikConfig<CreateBuildingFormState>['onSubmit'] = async values => {
    const { address_id, translations, ...restValues } = values
    const updatedTranslations = initializeTranslationsForCreation(translations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_building_in_progress'),
    )

    try {
      await mutateAsync(updatedValues)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_building_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_building_error'))
    }
  }

  const residentialsOptions = createAutocompleteOptions(residentials?.data, language)

  return {
    residentials: residentials?.data,
    setInitialValues,
    initialValues,
    isLoadingCreateBuilding,
    isLoadingResidentials,
    submitForm,
    searchValue,
    setSearchValue,
    residentialsOptions,
    createBuildingFormDefault,
  }
}
