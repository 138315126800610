import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { EmailsLogListItem } from '@/shared/api/topli/TopliApi'
import { formatDateForTable } from '@/shared/lib/utils'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { navigationRoutes } from '@/shared/routes/navigationRoutes'
import { Link } from 'react-router-dom'
import { useSMTPLog } from '@/entities/SMTPLog'
import { Badge } from '@mantine/core'

export const useLogTable = () => {
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const { t } = useTranslation(['common', 'SMTPlog'])
  const { language } = useLanguageStore()

  const debouncedSearch = useDebounce(search, 500)

  const {
    data: emailLog,
    isLoading: isLoadingEmailLog,
    isError: isErrorEmailLog,
    isFetching: isFetchingEmailLog,
  } = useSMTPLog({
    sort: memoizedSorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    search: debouncedSearch,
  })

  const fetchedEmailLog = emailLog?.data ?? []
  const totalRowCount = emailLog?.total ?? 0

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<EmailsLogListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'email',
        header: t('SMTPLog:recipient'),
        Cell: ({ row }) => <div className='text-sm font-semibold'>{row.original.email}</div>,
      },
      {
        accessorKey: 'status',
        header: t('SMTPLog:status'),
        Cell: ({ row }) => <Badge size='sm'>{row.original.status || '-'}</Badge>,
      },
      {
        accessorKey: 'template',
        header: t('SMTPLog:template'),
        enableSorting: false,
        Cell: ({ row }) => <div className='text-sm'>{row.original.template}</div>,
      },
      {
        accessorKey: 'user__fullname',
        header: t('SMTPLog:user'),
        Cell: ({ row }) =>
          row.original.user?.fullname ? (
            <Link
              to={navigationRoutes.userManagement}
              state={{ name: row.original.user.fullname }}
              className='text-sm font-medium text-blue hover:underline underline-offset-4'
            >
              {row.original.user.fullname}
            </Link>
          ) : (
            '-'
          ),
      },
      {
        accessorKey: 'created_at',
        header: t('SMTPLog:sent_date'),
        Cell: ({ row }) => formatDateForTable(row.original.created_at, language),
      },
    ],
    [language],
  )
  return {
    pagination,
    fetchedEmailLog,
    totalRowCount,
    columns,
    isErrorEmailLog,
    isFetchingEmailLog,
    isLoadingEmailLog,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  }
}
