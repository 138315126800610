import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useEditBuildingForm } from '../lib/use-edit-building-form'
import {
  CheckBoxField,
  DateField,
  MultiSelectField,
  NumberField,
  SelectField,
} from '@/shared/ui/FormFields'
import { FieldFormPolygonDrawing } from './FieldFormPolygonDrawing'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { editBuildingFormSchema } from '../lib/consts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'
import LanguageTabs from '@/features/MultilingualTabs/ui/LanguageTabs'
import { generateLanguageTabsConfig } from '@/shared/lib/utils'
import { MultilingualEntityForm } from '@/features/MultilingualTabs/ui/forms/MultilingualEntityForm'

type EditBuildingFormProps = {
  currentBuildingId: number
  selectResidentialId?: number
  onCloseModal: () => void
}

export const EditBuildingForm: FC<EditBuildingFormProps> = props => {
  const { currentBuildingId, selectResidentialId, onCloseModal } = props
  const { t } = useTranslation(['building', 'common'])
  const {
    initialValues,
    residentials,
    isLoadingEditBuilding,
    isLoadingResidentials,
    isErrorBuilding,
    isLoadingBuilding,
    submitForm,
    searchValue,
    setSearchValue,
    residentialsOptions,
    editBuildingFormDefault,
  } = useEditBuildingForm(currentBuildingId, onCloseModal)
  const { parkingOptions, accommodationTypeOptions } = useTranslatedEnums()

  const languageTabs = generateLanguageTabsConfig(
    isLoadingEditBuilding,
    MultilingualEntityForm,
    'building',
  )

  if (isErrorBuilding) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  if (isLoadingBuilding) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={editBuildingFormSchema(t, languageTabs)}
    >
      <Form>
        <LanguageTabs config={languageTabs} />
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <CheckBoxField
            name='activity'
            className='w-1/3'
            label={t('building:activity')}
            disabled={isLoadingEditBuilding}
          />
          <CheckBoxField
            name='done'
            className='w-1/3'
            label={t('building:done')}
            disabled={isLoadingEditBuilding}
          />
          <CheckBoxField
            name='hide_price'
            className='w-1/3'
            label={t('building:hide_price')}
            disabled={isLoadingEditBuilding}
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <InputAutocompleteField
            name='residential_id'
            label={t('building:residential')}
            onChange={setSearchValue}
            value={searchValue}
            className='flex flex-col w-full mb-4'
            data={residentialsOptions}
            disabled={!!selectResidentialId || isLoadingEditBuilding}
            isLoading={isLoadingResidentials}
            initialValue={editBuildingFormDefault.residential_id}
            required
          />
        </div>
        <div className='flex flex-row items-center mb-2'>
          <div className='text-sm font-medium mr-2'>{t('building:highlight_building')}:</div>
        </div>
        <div className='flex flex-row items-center mb-4'>
          <FieldFormPolygonDrawing
            name='area'
            residentials={residentials}
            className='flex flex-row items-center gap-4'
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <NumberField
            name='unitcount'
            label={t('building:unit_count')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditBuilding}
            min={0}
          />
          <NumberField
            name='car_unitcount'
            label={t('building:car_unit_count')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditBuilding}
            min={0}
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <MultiSelectField
            name='type'
            label={t('common:library_items.accommodation_type.sing')}
            options={accommodationTypeOptions}
            className='flex flex-col w-full mb-4'
            required
            disabled={isLoadingEditBuilding}
          />
          <SelectField
            name='parking_type'
            label={t('common:library_items.parking_type.sing')}
            options={parkingOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditBuilding}
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <NumberField
            name='nol'
            label={t('building:number_of_levels')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditBuilding}
            min={0}
            required
          />
          <DateField
            name='delivery_date'
            label={t('building:delivery_date')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditBuilding}
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditBuilding}>
            {isLoadingEditBuilding ? (
              <Loader size='xs' color='white' />
            ) : (
              t('building:buttons.save_changes')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
