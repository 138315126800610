import * as Yup from 'yup'
import { type EditCompanyFormState } from '../model/types'
import { validatePhoneNumber } from '@/shared/helpers/validatePhoneNumber'
import { CompanyType } from '@/shared/api/topli/TopliApi'
import { TFunction } from 'i18next'
import { defaultTranslationsEdit } from '@/entities/ContentTranslation/lib/translationDefaults'
import { generateTranslationSchema } from '@/shared/lib/generateTranslationSchema'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'

export const editCompanyFormDefault: EditCompanyFormState = {
  id: 0,
  featured: false,
  only_on_topli: false,
  year: new Date(),
  email: '',
  phone: '',
  address: '',
  website: '',
  type: [],
  responsible_id: 0,
  has_ai_translation: false,
  translations: defaultTranslationsEdit,
}

export const editCompanyFormSchema = (t: TFunction, languageTabs: LanguageTabConfig[]) => {
  return Yup.object().shape({
    featured: Yup.boolean(),
    only_on_topli: Yup.boolean().optional().nullable(),
    phone: Yup.string()
      .required(t('common:validation_messages.required'))
      .test('phone', t('common:validation_messages.invalid_phone'), value =>
        validatePhoneNumber(value),
      ),
    year: Yup.date()
      .required(t('common:validation_messages.required'))
      .test(
        'year',
        t('common:validation_messages.required'),
        value => !!new Date(value).getFullYear(),
      ),
    email: Yup.string()
      .email(t('common:validation_messages.invalid_email'))
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    address: Yup.string()
      .max(127, t('common:validation_messages.max', { count: 127 }))
      .required(t('common:validation_messages.required')),
    website: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    type: Yup.array()
      .of(
        Yup.mixed()
          .oneOf(Object.values(CompanyType) as number[], t('common:validation_messages.required'))
          .required(t('common:validation_messages.required')),
      )
      .ensure()
      .min(1, t('common:validation_messages.required')),
    responsible_id: Yup.number().min(1, t('common:validation_messages.required')),
    ...generateTranslationSchema(languageTabs, t, true).fields,
  })
}
