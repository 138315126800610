import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { OrderListItem } from '@/shared/api/topli/TopliApi'
import { findLabelByValue, getCurrentTranslation } from '@/shared/lib/utils'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@/shared/lib/useDebounce'
import { Badge } from '@mantine/core'
import { useClientsByPage } from '@/entities/Clients'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { statusColors } from './const'
import { useLocation } from 'react-router-dom'

export const useClientsTable = () => {
  const { language } = useLanguageStore()
  const location = useLocation()
  const [search, setSearch] = useState(location?.state?.name || '')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { titleOptions } = useTranslatedEnums()
  const { t } = useTranslation(['common', 'client'])
  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])
  const { orderStatusOptions } = useTranslatedEnums()
  const debouncedSearch = useDebounce(search, 500)

  const {
    data: clients,
    isLoading: isLoadingClients,
    isError: isErrorClients,
    isFetching: isFetchingClients,
  } = useClientsByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    language,
    debouncedSearch,
  )

  const fetchedClients = clients?.data ?? []
  const totalRowCount = clients?.total ?? 0

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  useEffect(() => {
    if (location.state?.name) {
      setSearch(location.state.name || '')
    }
  }, [location.state])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<OrderListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'contact_id',
        header: t('client:client'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>{row.original.contact.fullname}</div>
        ),
      },
      {
        accessorKey: 'created_by_id',
        header: t('client:agent'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>{row.original.created_by.fullname}</div>
        ),
      },
      {
        accessorKey: 'residentials_id',
        header: t('client:residential'),
        enableSorting: false,
        Cell: ({ row }) => (
          <div className='text-sm'>
            {getCurrentTranslation(row.original.residential, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'status',
        header: t('client:order_status'),
        Cell: ({ row }) => {
          return row.original.status ? (
            <Badge size='sm' color={statusColors[row.original.status]}>
              {findLabelByValue(orderStatusOptions, row.original.status)}
            </Badge>
          ) : (
            '–'
          )
        },
      },
      {
        accessorKey: 'comment',
        header: t('client:comment'),
        Cell: ({ row }) => <div className='text-sm'>{row.original.comment}</div>,
      },
    ],
    [language],
  )
  return {
    search,
    pagination,
    fetchedClients,
    totalRowCount,
    columns,
    isErrorClients,
    isFetchingClients,
    isLoadingClients,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  }
}
