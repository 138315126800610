import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useEditMessageTemplateApi } from '../api/use-edit-message-template-api'
import {
  useMessageTemplateById,
  useMessageTemplates,
} from '@/entities/MessageTemplates/api/message-tempates-api'
import { IEditMessageTemplateState } from '../model/types'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'

interface IEditMessageTemplateForm {
  onCloseModal: () => void
  seletectedTemplateId: number
}

export const useEditeMessageTemplateForm = ({
  onCloseModal,
  seletectedTemplateId,
}: IEditMessageTemplateForm) => {
  const [initialValues, setInitialValues] = useState<IEditMessageTemplateState>({
    id: seletectedTemplateId,
    name: '',
    comment: '',
    content: '',
  })
  const { templateNamesOptions } = useTranslatedEnums()
  const { data: messageTemplates, isLoading: isLoadingMessageTemplates } = useMessageTemplates()
  const { t } = useTranslation(['common'])

  const { data: messageTemplate, isLoading: isLoadingTemplate } =
    useMessageTemplateById(seletectedTemplateId)

  const { mutateAsync, isPending: isLoadingEditMessageTemplate } = useEditMessageTemplateApi()

  const submitForm: FormikConfig<IEditMessageTemplateState>['onSubmit'] = async values => {
    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.edit_template_in_progress'),
    )
    try {
      await mutateAsync(values)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_template_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_template_error'))
    }
  }

  useEffect(() => {
    if (messageTemplate) {
      setInitialValues({
        id: messageTemplate.id,
        name: messageTemplate.name,
        comment: messageTemplate.comment ? messageTemplate.comment : '',
        content: messageTemplate.content,
      })
    }
  }, [messageTemplate])

  const existingNames = new Set(messageTemplates?.data?.map(item => item.name) || [])

  const filteredTemplateNames = templateNamesOptions.filter(
    item => !existingNames.has(item?.value) || item?.value === messageTemplate?.name,
  )

  return {
    initialValues,
    filteredTemplateNames,
    isLoadingMessageTemplates,
    isLoadingEditMessageTemplate,
    isLoadingTemplate,
    submitForm,
  }
}
