import * as Yup from 'yup'
import { EditUnitAmenityFormState } from '../model/types'
import { TFunction } from 'i18next'
import { defaultTranslationsWithoutDescriptionEdit } from '@/entities/ContentTranslation/lib/translationDefaults'
import { generateTranslationSchema } from '@/shared/lib/generateTranslationSchema'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'

export const editUnitAmenityFormScheme = (t: TFunction, languageTabs: LanguageTabConfig[]) => {
  return Yup.object({
    ...generateTranslationSchema(languageTabs, t, true).fields,
  })
}

export const editUnitAmenityFormDefault: EditUnitAmenityFormState = {
  id: 0,
  has_ai_translation: false,
  pic_url: '',
  translations: defaultTranslationsWithoutDescriptionEdit,
}
