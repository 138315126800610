import { useEffect, useState } from 'react'
import { EditProfileFormState } from '../model/types'
import { editProfileFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useUserById, useUserStore } from '@/entities/User'
import { convertPhoneNumberToString } from '@/shared/lib/utils'
import { useEditCurrentUserApi } from '../api/edit-profile-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { useTranslation } from 'react-i18next'

export const useEditProfileForm = () => {
  const { user } = useUserStore()
  const { data: currentUser, isLoading: isLoadingUser } = useUserById(user?.id)
  const [initialValues, setInitialValues] = useState<EditProfileFormState>(
    () => editProfileFormDefault,
  )
  const { t } = useTranslation(['common'])

  const {
    mutateAsync,
    isPending: isPendingEditUser,
    isSuccess: isSuccessEditUser,
  } = useEditCurrentUserApi()

  const submitForm: FormikConfig<EditProfileFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_user_error'))
    }
  }

  useEffect(() => {
    if (currentUser) {
      setInitialValues(() => ({
        id: currentUser.id,
        fullname: currentUser.fullname || '',
        email: currentUser.email,
        phone: currentUser.phone ? convertPhoneNumberToString(currentUser.phone) : '',
        status: currentUser.status ? currentUser.status : 1,
        role: currentUser.role ? currentUser.role : 1,
        avatar_url: user?.avatar_url,
        is_manager: currentUser.is_manager ?? false,
        is_content_manager: currentUser.is_content_manager ?? false,
        hash_slug: currentUser.hash_slug,
      }))
    }
  }, [currentUser])

  useEffect(() => {
    if (isSuccessEditUser) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_user_success'),
      )
    }
  }, [isSuccessEditUser])

  return {
    initialValues,
    isPendingEditUser,
    submitForm,
  }
}
