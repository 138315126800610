import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { findLabelByValue, formatDateForTable } from '@/shared/lib/utils'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { Badge } from '@mantine/core'
import { useAgentActionsByPage } from '@/entities/PlatformActivity/api/agent-actions-api'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { generateLink } from './const'
import { UserActionsLogListItem } from '@/shared/api/topli/TopliApi'

export const useAgentActionsTable = () => {
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const { agentActionsOptions } = useTranslatedEnums()
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const { t } = useTranslation(['dashboard'])
  const { language } = useLanguageStore()

  const debouncedSearch = useDebounce(search, 500)

  const {
    data: agentActions,
    isLoading: isLoadingAgentActions,
    isError: isErrorAgentActions,
    isFetching: isFetchingAgentActions,
  } = useAgentActionsByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    debouncedSearch,
  )

  const fetchedAgentActions = agentActions?.data ?? []
  const totalRowCount = agentActions?.total ?? 0

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<UserActionsLogListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'created_at',
        header: t('dashboard:agent_actions.created_at'),
        Cell: ({ row }) => formatDateForTable(row.original.created_at, language),
      },
      {
        accessorKey: 'user__fullname',
        header: t('dashboard:agent_actions.username'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>{row.original.user.fullname}</div>
        ),
      },
      {
        accessorKey: 'user__email',
        header: t('dashboard:agent_actions.email'),
        Cell: ({ row }) => <div className='text-sm font-semibold'>{row.original.user.email}</div>,
      },
      {
        accessorKey: 'action',
        header: t('dashboard:agent_actions.action'),
        Cell: ({ row }) => {
          return row.original.action ? (
            <Badge size='sm'>{findLabelByValue(agentActionsOptions, row.original.action)}</Badge>
          ) : (
            '–'
          )
        },
      },
      {
        accessorKey: 'link',
        enableSorting: false,
        header: t('dashboard:agent_actions.link'),
        Cell: ({ row }) => generateLink(row.original, t),
      },
    ],
    [language],
  )

  return {
    pagination,
    fetchedAgentActions,
    totalRowCount,
    columns,
    isErrorAgentActions,
    isFetchingAgentActions,
    isLoadingAgentActions,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  }
}
