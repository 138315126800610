import { FC, useState, useRef, useEffect } from 'react'
import { Tabs } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { LanguageTabConfig } from '../model/types'

interface LanguageTabsProps {
  className?: string
  config: LanguageTabConfig[]
}

const defaultWrapperClassName = 'w-100 mx-[-2rem] pb-4 px-8 border-b border-white-600'

const LanguageTabs: FC<LanguageTabsProps> = ({ config, className }) => {
  const [activeTab, setActiveTab] = useState<string>(config[0].value)
  const { t } = useTranslation(['common'])
  const tabsListRef = useRef<HTMLDivElement>(null)

  const wrapperClassName = className ? className : defaultWrapperClassName

  const scrollToActiveTab = () => {
    if (!tabsListRef.current) return

    const activeTabElement = tabsListRef.current.querySelector(
      `[data-value="${activeTab}"]`,
    ) as HTMLElement
    if (!activeTabElement) return

    const container = tabsListRef.current
    const scrollPosition = activeTabElement.offsetLeft - 132

    container.scrollTo({
      left: scrollPosition,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    scrollToActiveTab()
  }, [activeTab])

  return (
    <div className={wrapperClassName}>
      <Tabs
        value={activeTab}
        onTabChange={value => setActiveTab(value as string)}
        variant='pills'
        classNames={{
          tabsList:
            'flex flex-nowrap overflow-x-auto w-[calc(100%+4rem)] mx-[-2rem] bg-white pt-4 pb-4 px-8 border-b border-white-600 bg-white hide-scroll z-10 scroll-smooth',
          tabLabel: 'font-medium',
          tab: 'data-[active=true]:bg-blue data-[active=true]:hover:bg-blue/90',
        }}
      >
        <Tabs.List ref={tabsListRef}>
          {config.map(({ title, value }) => (
            <Tabs.Tab key={value} value={value} data-value={value}>
              {t(`common:languages.${title}`)}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {config.map(({ value, component: Component, props }) => (
          <Tabs.Panel key={value} value={value} pt='sm'>
            <Component {...props} />
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  )
}

export default LanguageTabs
