import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button } from '@/shared/ui/Button/Button'
import { SelectField, TextAreaField, TextField } from '@/shared/ui/FormFields'
import { Badge, Tooltip } from '@mantine/core'
import { useCreateMessageTemplateForm } from '../lib/use-create-message-template-form'
import { handleCopy } from '@/shared/helpers/helpers'
import { TemplateConstants } from '@/widgets/Settings/LibraryView/MessageTemplatesView/lib/constant'
import { createMessageTemplateFormScheme } from '../lib/consts'

type CreateMessageTemplateFormProps = {
  onCloseModal: () => void
}

export const CreateMessageTemplateForm: FC<CreateMessageTemplateFormProps> = props => {
  const { onCloseModal } = props
  const { t } = useTranslation(['common'])
  const {
    initialValues,
    isLoadingCreateMessageTemplate,
    submitForm,
    filteredTemplateNames,
    isLoadingMessageTemplates,
  } = useCreateMessageTemplateForm(onCloseModal)

  const variables = Object.values(TemplateConstants)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createMessageTemplateFormScheme(t)}
      enableReinitialize
    >
      <Form>
        <div>
          <SelectField
            name='name'
            label={t('common:library_items.message_template.name')}
            options={filteredTemplateNames}
            className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
            disabled={isLoadingMessageTemplates}
            nothingFound={t('no_available_options')}
            required
          />
          <TextField
            name='comment'
            label={t('common:library_items.message_template.comment')}
            type='text'
            className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
          />
          <TextAreaField
            name='content'
            label='HTML'
            placeholder='<html dir="ltr" xmlns:o="urn:schemas-microsoft-com:office:office"></>'
            className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
            required
          />
        </div>
        <div className='flex flex-col'>
          <p className='text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2'>
            {t('common:library_items.message_template.variables')}:
          </p>
          <div className='flex flex-wrap gap-2 mb-4'>
            {variables.map((variable, index: number) => {
              const formVariable = '${' + variable + '}'
              return (
                <Tooltip
                  key={index}
                  label={t('common:copy')}
                  style={{ fontSize: 12 }}
                  transitionProps={{ transition: 'scale', duration: 300 }}
                >
                  <Badge
                    onClick={() => handleCopy(formVariable)}
                    style={{ textTransform: 'none', cursor: 'pointer' }}
                    size='sm'
                  >
                    {formVariable}
                  </Badge>
                </Tooltip>
              )
            })}
          </div>
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateMessageTemplate}>
            {t('common:library_items.message_template.buttons.add_template')}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
