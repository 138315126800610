import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { Coords } from 'google-map-react'
import { useAddress, useMap, useMarker } from '@shared/ui/Map'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useTranslation } from 'react-i18next'

type ShowOnMapModalProps = {
  dialogProps: AlertDialogProps
  coords: Coords | null
}

export const ShowOnMapModal: React.FC<ShowOnMapModalProps> = props => {
  const { coords, dialogProps } = props
  const [setElementRef, map] = useMap()
  const [localCoords, setLocalCoords] = useState<Coords | null>(null)
  const markerLibrary = useMarker()
  const marker = useRef<google.maps.Marker | null>(null)
  const { t } = useTranslation(['address'])

  useEffect(() => {
    setLocalCoords(coords)
  }, [coords])

  useEffect(() => {
    if (map !== null && markerLibrary !== null) {
      marker.current = new markerLibrary.Marker({ map })
    }
  }, [map, markerLibrary])

  const { getAddressInfoByCoords } = useAddress()
  useEffect(() => {
    const updateMap = async () => {
      if (!map || !localCoords) return

      map.setCenter(localCoords)

      const setAddressInfo = async () => {
        const formattedAddress = (await getAddressInfoByCoords(localCoords))?.formatted
        marker.current?.setPosition(localCoords)
        marker.current?.setTitle(formattedAddress)
      }
      setAddressInfo()
    }
    updateMap()
  }, [map, localCoords, getAddressInfoByCoords])

  return (
    <>
      <Dialog {...dialogProps}>
        <DialogContent className={'max-w-4xl'}>
          <DialogHeader>
            <DialogTitle>{t('address:map.location_on_the_map')}</DialogTitle>
          </DialogHeader>
          <div className={'h-[600px] w-full'} ref={setElementRef} />
        </DialogContent>
      </Dialog>
    </>
  )
}
