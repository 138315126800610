import { LanguageCode } from '@/features/MultilingualTabs/model/config'
import { MRT_Localization } from 'mantine-react-table'
import { MRT_Localization_EN } from 'mantine-react-table/locales/en'
import { MRT_Localization_RU } from 'mantine-react-table/locales/ru'
import { MRT_Localization_TR } from 'mantine-react-table/locales/tr'

export const LANGUAGE_OPTIONS = [
  { label: 'English', value: LanguageCode.en, country: 'US' },
  { label: 'Русский', value: LanguageCode.ru, country: 'RU' },
  { label: 'Türkçe', value: LanguageCode.tr, country: 'TR' },
]

export const getLocalization = (language: string): Partial<MRT_Localization> => {
  switch (language) {
    case LanguageCode.ru:
      return MRT_Localization_RU
    case LanguageCode.tr:
      return MRT_Localization_TR
    case LanguageCode.en:
    default:
      return MRT_Localization_EN
  }
}
