import { useQuery } from '@tanstack/react-query'
import { ListArgs, TemplateIn, topliApi } from '@/shared/api/topli/TopliApi'

export const messageTemplatesQueryKey = 'messageTemplates'
export const messageTemplatesByPageQueryKey = 'messageTemplatesByPage'
export const messageTemplateByIdQueryKey = 'messageTemplateById'

export const fetchMessageTemplates = async () => {
  const response = await topliApi.template.list({})
  return response
}

export const fetchMessageTemplatesByPage = async (
  limit: number,
  offset: number,
  sort: string | null,
  search: string,
) => {
  const response = await topliApi.template.list({
    limit,
    offset,
    sort,
    name__icontains: search,
  })
  return response
}

export const fetchMessageTemplateById = async (id: number) => {
  const response = await topliApi.template.get(id)
  return response
}

export const updateMessageTemplate = async (id: number, data: TemplateIn) => {
  const response = await topliApi.template.update(id, data)
  return response
}

export const deleteMessageTemplate = async (id: number) => {
  const response = await topliApi.template.delete(id)
  return response
}

export const useMessageTemplates = () =>
  useQuery({
    queryKey: [messageTemplatesQueryKey],
    queryFn: () => fetchMessageTemplates(),
  })

export const useMessageTemplatesByPage = ({ sort, limit, offset, search }) => {
  return useQuery({
    queryKey: [messageTemplatesByPageQueryKey, sort, offset, limit, search],
    queryFn: () => fetchMessageTemplatesByPage(limit, offset, sort, search || ''),
  })
}

export const useMessageTemplateById = (id: number) => {
  return useQuery({
    queryKey: [messageTemplateByIdQueryKey, id],
    queryFn: () => fetchMessageTemplateById(id),
    enabled: !!id,
  })
}
