import { useEffect, useState } from 'react'
import { createUnitFormDefault } from './consts'
import { CreateUnitFormState } from '../model/types'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import {
  createAutocompleteOptions,
  createTranslatedSelectOptions,
  getCurrentTranslation,
  hasAiTranslation,
  matchTranslations,
} from '@/shared/lib/utils'
import { useCreateUnitApi } from '../api/create-unit-form-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { ImagableType } from '@/shared/api/topli/TopliApi'
import { useUnitAmenitiesByPage, useUnitById } from '@/entities/Unit'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { initializeTranslationsForCreation } from '@/shared/lib/initializeTranslationsForCreation'
import { useBuildingById, useSearchBuildingsByText } from '@/entities/Building/api/building-api'
import { useDebounce } from '@/shared/lib/useDebounce'
import { updateTranslationsForCreation } from '@/shared/lib/updateTranslationsForEditing'
import { EditTranslations } from '@/entities/ContentTranslation/model/types'

export const useCreateUnitForm = (
  onCloseModal: () => void,
  currentUnitId?: number,
  selectBuildingId?: number,
) => {
  const [originalTranslations, setOriginalTranslations] = useState<EditTranslations | null>(null)
  const [searchBuilding, setSearchBuilding] = useState<string>('')
  const { language } = useLanguageStore()
  const { data: selectedBuilding } = useBuildingById(selectBuildingId)
  const debouncedSearchValue = useDebounce(searchBuilding, 500)
  const { t } = useTranslation(['common'])

  const { data: amenities, isLoading: isLoadingAmenities } = useUnitAmenitiesByPage(
    1000,
    0,
    language,
  )

  const {
    data: currentUnit,
    isLoading: isLoadingUnit,
    isError: isErrorUnit,
  } = useUnitById(currentUnitId)

  const { data: buildings, isLoading: isLoadingBuildings } = useSearchBuildingsByText(
    language,
    debouncedSearchValue,
  )

  const [initialValues, setInitialValues] = useState<CreateUnitFormState>(
    () => createUnitFormDefault,
  )

  const { mutateAsync, isPending: isLoadingCreateUnit } = useCreateUnitApi(ImagableType.Unit)

  useEffect(() => {
    if (currentUnit) {
      const {
        has_ai_translation,
        translations,
        activity,
        fee,
        fee_local,
        agent_fee,
        agent_fee_local,
        condition,
        type,
        bathrooms,
        bedrooms,
        building,
        video_url,
        level,
        size,
        net_size,
        lotSize,
        hide_price,
        amenities,
        prices,
      } = currentUnit

      setSearchBuilding(getCurrentTranslation(building, language, 'name'))

      const matchedTranslations = matchTranslations(
        translations,
        createUnitFormDefault.translations,
      )

      setOriginalTranslations({ ...matchedTranslations })

      setInitialValues({
        has_ai_translation: has_ai_translation ?? false,
        activity: activity ?? false,
        fee: fee ?? 0,
        fee_local: fee_local ?? 0,
        agent_fee: agent_fee ?? 0,
        agent_fee_local: agent_fee_local ?? 0,
        condition: condition ?? null,
        type: type ?? 1,
        level: level ?? 1,
        size: size ?? 0,
        net_size: net_size ?? 0,
        lotSize: lotSize ?? 0,
        bathrooms: bathrooms ?? 0,
        bedrooms: bedrooms ?? 0,
        translations: matchedTranslations,
        amenities: (amenities || []).map(amenity => amenity.id),
        hide_price: hide_price ?? false,
        video_url: video_url ?? '',
        building_id: building.id,
        prices: prices
          ?.filter(item => item.main)
          .map(price => ({
            price: Number(price.price / 100),
            is_local: !!price.is_local,
            currency: price.currency || 1,
            main: price.main ?? false,
          })),
      })
    }
    if (selectBuildingId && selectedBuilding) {
      setSearchBuilding(getCurrentTranslation(selectedBuilding, language, 'name'))
      setInitialValues(prev => ({
        ...prev,
        building_id: selectBuildingId,
        fee: selectedBuilding?.residential.fee ? selectedBuilding?.residential.fee : 0,
        fee_local: selectedBuilding?.residential.fee_local
          ? selectedBuilding?.residential.fee_local
          : 0,
        agent_fee: selectedBuilding?.residential.agent_fee
          ? selectedBuilding?.residential.agent_fee
          : 0,
        agent_fee_local: selectedBuilding?.residential.agent_fee_local
          ? selectedBuilding?.residential.agent_fee_local
          : 0,
      }))
    }
  }, [currentUnit, selectBuildingId, selectedBuilding])

  const submitForm: FormikConfig<CreateUnitFormState>['onSubmit'] = async values => {
    const { translations, building_id, ...restValues } = values
    const updatedTranslations = initializeTranslationsForCreation(translations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)
    // Temporarily commented out the translation creation during cloning,
    // as the "name" field is currently disabled, and the translation will be taken from the Turkish form before submission

    // const updatedTranslations = !currentUnitId
    //   ? initializeTranslationsForCreation(translations)
    //   : updateTranslationsForCreation(translations, originalTranslations)
    // const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const building_level = buildings?.data?.find(building => building.id === building_id)?.nol ?? 0

    const updatedValues = {
      ...restValues,
      building_level,
      building_id,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }
    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_unit_in_progress'),
    )

    try {
      await mutateAsync(updatedValues)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_unit_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_unit_error'))
    }
  }

  const amenitiesOptions = createTranslatedSelectOptions(amenities?.data)

  const buildingsOptions = createAutocompleteOptions(buildings?.data, language)

  return {
    initialValues,
    amenitiesOptions,
    isLoadingCreateUnit,
    isLoadingAmenities,
    submitForm,
    buildingsOptions,
    searchBuilding,
    isLoadingBuildings,
    buildings,
    setSearchBuilding,
    createUnitFormDefault,
  }
}
