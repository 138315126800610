import * as Yup from 'yup'
import { UserRole, AuthUserStatus } from '@/shared/api/topli/TopliApi'
import { EditUserFormState } from '../model/types'
import { validatePhoneNumber } from '@/shared/helpers/validatePhoneNumber'
import { TFunction } from 'i18next'

export const editUserFormDefault: EditUserFormState = {
  id: 0,
  status: AuthUserStatus.Active,
  fullname: '',
  email: '',
  phone: '',
  role: UserRole.Admin,
  avatar_url: null,
  company_name: '',
  company_logo_url: null,
  is_manager: false,
  is_content_manager: false,
  hash_slug: '',
}

export const editUserFormScheme = (t: TFunction) => {
  return Yup.object().shape({
    fullname: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(127, t('common:validation_messages.max', { count: 127 })),
    email: Yup.string()
      .email(t('common:validation_messages.invalid_email'))
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    phone: Yup.string()
      .required(t('common:validation_messages.required'))
      .test('phone', t('common:validation_messages.invalid_phone'), value =>
        validatePhoneNumber(value),
      ),
    company_name: Yup.string().min(2, t('common:validation_messages.min', { count: 2 })),
    is_manager: Yup.boolean(),
    is_content_manager: Yup.boolean(),
    status: Yup.mixed<AuthUserStatus>().oneOf(Object.values(AuthUserStatus) as number[]),
    role: Yup.mixed<UserRole>().oneOf(Object.values(UserRole) as number[]),
  })
}
