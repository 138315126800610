import { topliApi } from '@/shared/api/topli/TopliApi'
import { EditTranslationData } from '../model/types'
import { getEntityType } from '@/shared/lib/utils'
import { LanguageCode } from '@/features/MultilingualTabs/model/config'

// Currently, the 'name' field for each object is set to the Turkish version (tr.name)
// because the input fields are temporarily disabled
// We don't need translations for 'name' in different languages at the moment
export const editTranslation = async (params: EditTranslationData, objectId: number) => {
  const { translations, modelType } = params
  const languages = Object.values(LanguageCode)
  const turkishName = translations.tr?.name || ''

  const bodyParam = languages.map(lang => {
    const shortLang = lang.split('-')[0]
    const translationData = translations[shortLang] || {}

    return {
      language: lang,
      ...translationData,
      name: turkishName,
      ...getEntityType(modelType, objectId),
    }
  })

  const response = await topliApi.translations.update({ params: bodyParam, type: modelType }, {})
  return response
}
