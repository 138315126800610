import * as Yup from 'yup'
import { EditResidentialAmenityFormState } from '../model/types'
import { TFunction } from 'i18next'
import { defaultTranslationsWithoutDescriptionEdit } from '@/entities/ContentTranslation/lib/translationDefaults'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'
import { generateTranslationSchema } from '@/shared/lib/generateTranslationSchema'

export const editResidentialAmenityFormScheme = (
  t: TFunction,
  languageTabs: LanguageTabConfig[],
) => {
  return Yup.object({
    ...generateTranslationSchema(languageTabs, t, true).fields,
  })
}

export const editResidentialAmenityFormDefault: EditResidentialAmenityFormState = {
  id: 0,
  has_ai_translation: false,
  pic_url: '',
  translations: defaultTranslationsWithoutDescriptionEdit,
}
