import { ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { EditUnitFormState, EditUnitResponse } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  currentUnitByIdQueryKey,
  unitListsByBuildingIdQueryKey,
  unitListsByPageQueryKey,
} from '@/entities/Unit/api/unit-api'
import { deletePrice, updatePrices } from '@/features/Price/EditPriceFormField'
import { errorHandler } from '@/shared/lib/errorHandler'
import { editTranslation } from '@/entities/ContentTranslation'
import { EditPriceFormState } from '@/features/Price/EditPriceFormField/model/types'
import { currentBuildingByIdQueryKey } from '@/entities/Building'
import { transformPricesToServer } from '@/shared/helpers/priceHelpers'

export const editUnit = async (state: EditUnitFormState): Promise<EditUnitResponse> => {
  const {
    id,
    prices,
    id_deleted_price,
    translations,
    building_id,
    level,
    building_level,
    originalPrices,
    ...restState
  } = state

  const isLastFloor = building_level === level

  const response = await topliApi.unit.update(id, {
    name: translations.tr.name,
    description: translations?.tr.description,
    last_floor: isLastFloor,
    level,
    building_id,
    unit_positions: {},
    agents_comments: {},
    clients_likes: {},
    ...restState,
  })
  const modifiedResponse = { ...response, prices, id_deleted_price, originalPrices }
  return modifiedResponse
}

export const useEditUnitApi = ({
  isEditFromTable = false,
}: { isEditFromTable?: boolean } = {}): UseMutationResult<
  EditUnitResponse,
  Error,
  EditUnitFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editUnit(state),
    onSuccess: async (res, state) => {
      try {
        if (res.id_deleted_price.length > 0) {
          await Promise.all(res.id_deleted_price?.map(async price => await deletePrice(price)))
        }

        const prices: EditPriceFormState[] = transformPricesToServer(res.prices, res.id)
        await updatePrices(prices, res.originalPrices)

        if (!isEditFromTable) {
          await editTranslation(
            { translations: state.translations, modelType: ModelTypes.Unit },
            res.id,
          )
        }

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [unitListsByPageQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentUnitByIdQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentBuildingByIdQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [unitListsByBuildingIdQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
