import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik, FormikErrors } from 'formik'
import { useCreateUnitForm } from '../lib/use-create-unit-form'
import { createUnitFormScheme } from '../lib/consts'
import { Text, Loader } from '@mantine/core'
import { generateLanguageTabsConfig } from '@/shared/lib/utils'
import {
  CheckBoxField,
  MultiSelectField,
  NumberField,
  SelectField,
  TextField,
} from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'
import { createPriceFormFieldDefault } from '@/features/Price/CreatePriceFormField/lib/consts'
import { CreatePriceFieldArray } from '@/features/Price/CreatePriceFormField'
import LanguageTabs from '@/features/MultilingualTabs/ui/LanguageTabs'
import { MultilingualEntityForm } from '@/features/MultilingualTabs/ui/forms/MultilingualEntityForm'
import { CreateUnitFormState } from '../model/types'

type CreateUnitFormProps = {
  selectBuildingId?: number
  currentUnitId?: number
  onCloseModal: () => void
}

type FormikSetValueFunction = (
  field: string,
  value: any,
  shouldValidate?: boolean,
) => Promise<void | FormikErrors<CreateUnitFormState>>

export const CreateUnitForm: FC<CreateUnitFormProps> = ({
  selectBuildingId,
  currentUnitId,
  onCloseModal,
}) => {
  const {
    initialValues,
    buildingsOptions,
    amenitiesOptions,
    isLoadingCreateUnit,
    isLoadingBuildings,
    isLoadingAmenities,
    searchBuilding,
    setSearchBuilding,
    submitForm,
    createUnitFormDefault,
    buildings,
  } = useCreateUnitForm(onCloseModal, currentUnitId, selectBuildingId)

  const { conditionOptions, accommodationTypeOptions } = useTranslatedEnums()
  const { t } = useTranslation(['unit', 'common'])

  const languageTabs = generateLanguageTabsConfig(
    isLoadingCreateUnit,
    MultilingualEntityForm,
    'unit',
  )

  const setBuildingId = (
    setFieldValue: FormikSetValueFunction,
    newBuildingId: number | undefined,
  ) => {
    if (newBuildingId) {
      const newBuilding = buildings?.data?.find(b => b.id === newBuildingId)

      setFieldValue('fee', newBuilding?.residential.fee ? newBuilding?.residential.fee : 0)
      setFieldValue(
        'fee_local',
        newBuilding?.residential.fee_local ? newBuilding?.residential.fee_local : 0,
      )
      setFieldValue(
        'agent_fee',
        newBuilding?.residential.agent_fee ? newBuilding?.residential.agent_fee : 0,
      )
      setFieldValue(
        'agent_fee_local',
        newBuilding?.residential.agent_fee_local ? newBuilding?.residential.agent_fee_local : 0,
      )
    }

    setFieldValue('building_id', newBuildingId)
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createUnitFormScheme(t, languageTabs, buildings)}
      enableReinitialize
    >
      {({ setFieldValue }) => (
        <Form>
          <LanguageTabs config={languageTabs} />
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <CheckBoxField
              name='activity'
              className='w-1/3'
              label={t('unit:activity')}
              disabled={isLoadingCreateUnit}
            />
            <CheckBoxField
              name='hide_price'
              className='w-1/3'
              label={t('unit:hide_price')}
              disabled={isLoadingCreateUnit}
            />
          </div>
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <InputAutocompleteField
              name='building_id'
              label={t('unit:building')}
              onChange={setSearchBuilding}
              onItemSubmit={buildingId => setBuildingId(setFieldValue, buildingId)}
              value={searchBuilding}
              className='flex flex-col w-full mb-4'
              data={buildingsOptions}
              disabled={isLoadingCreateUnit || !!selectBuildingId}
              isLoading={isLoadingBuildings}
              initialValue={createUnitFormDefault.building_id}
              required
            />
            <SelectField
              name='type'
              label={t('common:library_items.accommodation_type.sing')}
              options={accommodationTypeOptions}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnit}
              required
            />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <NumberField
              name='size'
              label={t('unit:gross_size')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnit}
              required
              precision={2}
              step={0.01}
              min={0}
            />
            <NumberField
              name='net_size'
              label={t('unit:net_size')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnit}
              required
              precision={2}
              step={0.01}
              min={0}
            />
            <NumberField
              name='lotSize'
              label={t('unit:lot_size')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnit}
              precision={2}
              step={0.01}
              min={0}
            />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <NumberField
              name='bedrooms'
              label={t('unit:bedrooms')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnit}
              required
              min={0}
            />
            <NumberField
              name='bathrooms'
              label={t('unit:bathrooms')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnit}
              required
              min={0}
            />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <TextField
              name='level'
              label={t('unit:level')}
              type='number'
              className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
              disabled={isLoadingCreateUnit}
            />
            <SelectField
              name='condition'
              label={t('common:library_items.condition.sing')}
              options={conditionOptions}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnit}
            />
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <TextField
              name='video_url'
              label={t('unit:video_url')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateUnit}
            />
          </div>
          <MultiSelectField
            name='amenities'
            label={t('unit:amenities')}
            options={amenitiesOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingAmenities || isLoadingCreateUnit}
          />
          <div className='flex flex-col w-full mb-4'>
            <div className='text-md font-bold font-body mb-2 mt-4 text-gray-400'>
              {t('unit:commission')}
            </div>
            <NumberField
              name='fee'
              label={t('unit:commission_for_international_clients')}
              className='flex flex-col w-full mb-4'
              precision={2}
              step={0.01}
              min={0}
            />
            <NumberField
              name='fee_local'
              label={t('unit:commission_for_local_clients')}
              className='flex flex-col w-full mb-4'
              precision={2}
              step={0.01}
              min={0}
            />
            <NumberField
              name='agent_fee'
              label={t('residential:agent_commission_for_international_clients')}
              className='flex flex-col w-full mb-4'
              precision={2}
              step={0.01}
              min={0}
            />
            <NumberField
              name='agent_fee_local'
              label={t('residential:agent_commission_for_local_clients')}
              className='flex flex-col w-full mb-4'
              precision={2}
              step={0.01}
              min={0}
            />
          </div>
          <div className='flex flex-col w-full mb-4'>
            <Text component='div' className='text-md font-bold font-body mb-2 text-gray-400'>
              {t('unit:price')}
            </Text>
            <CreatePriceFieldArray namePrice='prices' defaultValue={createPriceFormFieldDefault} />
          </div>
          <div className='flex flex-row w-full'>
            <Button type='submit' size='md' color='base' disabled={isLoadingCreateUnit}>
              {isLoadingCreateUnit ? (
                <Loader size='xs' color='white' />
              ) : (
                t('unit:buttons.add_unit')
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
