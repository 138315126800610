import { FC } from 'react'
import { TextAreaField, TextField } from '@/shared/ui/FormFields'
import { useTranslation } from 'react-i18next'

type CreateFormProps = {
  isLoading?: boolean
  languageCode: string
  isRtl?: boolean
  required?: boolean
  entityType: 'building' | 'residential' | 'unit' | 'company'
}

export const MultilingualEntityForm: FC<CreateFormProps> = props => {
  const { isLoading, required, entityType, isRtl = false, languageCode } = props
  const { t } = useTranslation(['building', 'residential', 'unit', 'company'])

  const isDisabled = isLoading || languageCode !== 'tr'

  return (
    <>
      <div className='flex flex-row items-center space-x-4'>
        <TextField
          name={`translations.${languageCode}.name`}
          label={t(`${entityType}:name`)}
          className={`flex flex-col w-full mb-4 ${isDisabled ? 'cursor-not-allowed' : ''}`}
          required={required}
          disabled={isDisabled} // Temporarily disabled name input for non-Turkish languages
          isRtl={isRtl}
        />
      </div>
      <TextAreaField
        name={`translations.${languageCode}.description`}
        label={t(`${entityType}:description`)}
        className='flex flex-col w-full mb-4'
        disabled={isLoading}
        isRtl={isRtl}
      />
    </>
  )
}
