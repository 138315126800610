import {
  EditUploadedPhotosModal,
  PhotoOwnerCaption,
  currentPhotosByEntityIdQueryKey,
} from '@/entities/Photos'
import { currentResidentialByIdQueryKey, residentialsByPageQueryKey } from '@/entities/Residential'
import { DeletePhotoAlert } from '@/features/Photos/DeletePhotoAlert'
import { EditPhotoCropModal } from '@/features/Photos/EditPhotoCropModal'
import { UploadPhotosForm } from '@/features/Photos/UploadPhotosForm'
import { ImagableType, Residential } from '@/shared/api/topli/TopliApi'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { usePhotosOrderState } from '@/shared/lib/usePhotosOrderState'
import {
  convertPhoneNumberToString,
  getCurrentTranslation,
  findLabelByValue,
  formatDateWithoutTime,
} from '@/shared/lib/utils'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { Title, ActionIcon as MantineActionButton, Text, Spoiler, Badge } from '@mantine/core'
import { ImageIcon, MapPin, PenIcon } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type ResidentialCardProps = {
  residential?: Residential
  onOpenMapModal: (residential: Residential) => void
  onOpenEditModal: (residential: Residential) => void
  onOpenFullDescription: (residential: Residential) => void
}

export const ResidentialCard: FC<ResidentialCardProps> = props => {
  const { residential, onOpenMapModal, onOpenEditModal, onOpenFullDescription } = props
  const { purposeOptions, classOptions } = useTranslatedEnums()
  const { t } = useTranslation(['common', 'residential'])
  const { language } = useLanguageStore()

  const [selectPhotoId, setSelectedPhotoId] = useState<number | null>(null)
  const [openUploadedPhotosDialog, setOpenUploadedPhotosDialog] = useState(false)
  const [openEditCropPhotoDialog, setOpenEditCropPhotoDialog] = useState(false)
  const [openDeletePhotoDialog, setOpenDeletePhotoDialog] = useState(false)
  const [lastImageCropTimestamp, setLastImageCropTimestamp] = useState<number | null>(null)
  const {
    handleCheckPhotosOrderChanged: handleResidentialPhotosOrderChanged,
    handleInvalidateIfChanged: invalidateResidentialPhotos,
  } = usePhotosOrderState({
    queryKey: residentialsByPageQueryKey,
  })

  const handleOpenUploadedPhotos = () => {
    setOpenUploadedPhotosDialog(true)
  }

  const handleOpenEditCropPhoto = (photoId: number) => {
    setSelectedPhotoId(photoId)
    setOpenEditCropPhotoDialog(true)
  }

  const handleDeletePhoto = (photoId: number) => {
    setSelectedPhotoId(photoId)
    setOpenDeletePhotoDialog(true)
  }

  const handleCloseCropModal = () => {
    setSelectedPhotoId(null)
    setOpenEditCropPhotoDialog(false)
  }

  const handleCloseUploadedPhotosModal = (isOpen: boolean) => {
    setOpenUploadedPhotosDialog(isOpen)

    if (!isOpen) {
      invalidateResidentialPhotos()
    }
  }

  return (
    <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-8 gap-y-4 mb-5'>
      <div className='flex relative flex-row justify-between w-full'>
        <Title order={1} className='text-2xl text-left font-body'>
          {getCurrentTranslation(residential, language, 'name')}
        </Title>
        <div className='flex absolute flex-row items-center gap-x-2 right-0'>
          {residential?.photos && (
            <MantineActionButton size='lg' color='primary' onClick={handleOpenUploadedPhotos}>
              <ImageIcon size={18} />
            </MantineActionButton>
          )}
          {residential && (
            <MantineActionButton
              size='lg'
              color='primary'
              onClick={() => onOpenEditModal(residential)}
            >
              <PenIcon size={18} />
            </MantineActionButton>
          )}
        </div>
      </div>
      <div className='flex flex-row w-full'>
        <div className='flex flex-col items-start w-[calc(100%/2)]'>
          <Spoiler maxHeight={110} showLabel='Show more' hideLabel='Hide' className='mb-4'>
            <Text
              lineClamp={4}
              classNames={{
                root: 'flex flex-col items-start w-full mb-6 text-base font-body',
              }}
            >
              {getCurrentTranslation(residential, language, 'description')}
            </Text>
          </Spoiler>
        </div>
      </div>
      <div className='flex flex-row flex-wrap w-[calc(100%+1rem)] items-stretch mx-[-0.5rem] gap-y-4'>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{t('residential:delivery_date')}</div>
          <div className='text-sm font-body font-medium'>
            {residential?.delivery_date
              ? formatDateWithoutTime(residential.delivery_date, language)
              : '–'}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{t('common:library_items.class.sing')}</div>
          <div className='flex flex-row flex-wrap gap-1'>
            {residential?.clas && (
              <Badge size='sm' color='blue'>
                {findLabelByValue(classOptions, residential.clas)}
              </Badge>
            )}
          </div>
        </div>

        {/* <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>Heating</div>
          <div className='text-sm font-body font-medium'>
            {residential?.heating && HeatingCaption[residential.heating]}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>Prices</div>
          <div className='text-sm font-body font-medium'>
            {displayPriceFromToInLocaleFormat(residential?.prices, residential?.currency)}
          </div>
        </div> */}
        <div className='flex flex-col items-start w-[calc(1/2*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{t('common:library_items.purpose.sing')}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.purpose &&
              residential.purpose.map(item => (
                <Badge key={item} size='sm' color='blue'>
                  {findLabelByValue(purposeOptions, item)}
                </Badge>
              ))}
          </div>
        </div>
      </div>
      <div className='flex flex-row flex-wrap w-[calc(100%+1rem)] items-stretch mx-[-0.5rem] gap-y-4'>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{`${t(
            'residential:commission_for_international_clients',
          )},%`}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.fee && (
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col items-start w-full mb-6 text-base font-body',
                }}
              >
                {residential?.fee}
              </Text>
            )}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{`${t(
            'residential:commission_for_local_clients',
          )},%`}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.fee_local && (
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col items-start w-full mb-6 text-base font-body',
                }}
              >
                {residential?.fee_local}
              </Text>
            )}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{`${t(
            'residential:agent_commission_for_international_clients',
          )},%`}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.agent_fee && (
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col items-start w-full mb-6 text-base font-body',
                }}
              >
                {residential?.agent_fee}
              </Text>
            )}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{`${t(
            'residential:agent_commission_for_local_clients',
          )},%`}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.agent_fee_local && (
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col items-start w-full mb-6 text-base font-body',
                }}
              >
                {residential?.agent_fee_local}
              </Text>
            )}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{`${t('residential:title_deed')},%`}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.title_deed && (
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col items-start w-full mb-6 text-base font-body',
                }}
              >
                {residential?.title_deed}
              </Text>
            )}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{`${t('residential:vat')},%`}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.vat && (
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col items-start w-full mb-6 text-base font-body',
                }}
              >
                {residential?.vat}
              </Text>
            )}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{`${t('residential:downpayment')},%`}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.downpayment && (
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col items-start w-full mb-6 text-base font-body',
                }}
              >
                {residential?.downpayment}
              </Text>
            )}
          </div>
        </div>
        <div className='flex flex-col items-start w-[calc(1/4*100%-1rem)] gap-2 mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{t('residential:duration_month')}</div>
          <div className='flex flex-row flex-wrap w-full gap-2'>
            {residential?.duration && (
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col items-start w-full mb-6 text-base font-body',
                }}
              >
                {residential?.duration}
              </Text>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-row flex-wrap w-[calc(100%+1rem)] mx-[-0.5rem]'>
        {residential?.manager && (
          <div className='flex flex-col items-start w-[calc(1/3*100%-1rem)] mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
            <div className='text-sm font-semibold mb-1'>{residential?.manager.username}</div>
            <a
              href={`mailto:${residential?.manager.email}`}
              className='text-sm mb-1 text-black hover:text-blue'
              target='_blank'
            >
              {residential?.manager.email}
            </a>
            <div className='text-sm'>
              {residential?.manager.phone
                ? convertPhoneNumberToString(residential?.manager.phone)
                : '–'}
            </div>
          </div>
        )}
        {residential?.company && (
          <div className='flex flex-col items-start w-[calc(1/3*100%-1rem)] mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
            <div className='text-sm font-semibold mb-1'>{residential?.company.name}</div>
            <a
              href={`mailto:${residential?.company.email}`}
              className='text-sm mb-1 text-black hover:text-blue'
              target='_blank'
            >
              {residential?.company.email}
            </a>
            <div className='text-sm'>
              {residential?.company.phone
                ? convertPhoneNumberToString(residential?.company.phone)
                : '–'}
            </div>
          </div>
        )}
        {residential?.address && (
          <div className='flex flex-col items-start w-[calc(1/3*100%-1rem)] mx-[0.5rem] px-4 py-4 rounded-lg border border-white-600'>
            <div className='flex flex-row items-center w-full space-x-2'>
              <MantineActionButton
                variant='light'
                className='text-blue'
                onClick={() => onOpenMapModal(residential)}
              >
                <MapPin size={20} />
              </MantineActionButton>
              <div className='text-sm text-black'>{residential.address.formatted}</div>
            </div>
          </div>
        )}
      </div>
      {residential?.amenities && residential?.amenities?.length > 0 && (
        <div className='flex flex-col items-start w-full mx-[0.5rem] gap-2 px-4 py-4 rounded-lg border border-white-600'>
          <div className='text-xs font-body mb-1'>{t('residential:amenities')}</div>
          <div className='flex flex-row flex-wrap gap-1'>
            {residential?.amenities?.map(amenity => (
              <Badge key={amenity.id} size='sm' color='purple'>
                {getCurrentTranslation(amenity, language, 'name')}
              </Badge>
            ))}
          </div>
        </div>
      )}
      {selectPhotoId && openDeletePhotoDialog && (
        <DeletePhotoAlert
          open={openDeletePhotoDialog}
          onOpenChange={setOpenDeletePhotoDialog}
          selectPhotoId={selectPhotoId}
          queryKeyForUpdate={PhotoOwnerCaption.Unit}
          onCloseModal={handleCloseCropModal}
        />
      )}
      {residential && selectPhotoId && (
        <EditPhotoCropModal
          dialogProps={{
            open: openEditCropPhotoDialog,
            onOpenChange: setOpenEditCropPhotoDialog,
          }}
          onCloseModal={() => {
            handleCloseCropModal()
            setLastImageCropTimestamp(new Date().getTime())
          }}
          selectedPhotoId={selectPhotoId}
          queryKeyForObjectUpdate={currentResidentialByIdQueryKey}
          queryKeyForPageUpdate={residentialsByPageQueryKey}
          obj_id={residential.id}
          owner={PhotoOwnerCaption.Residential}
        />
      )}
      {residential && (
        <EditUploadedPhotosModal
          dialogProps={{
            open: openUploadedPhotosDialog,
            onOpenChange: value => {
              handleCloseUploadedPhotosModal(value)
            },
          }}
          obj_id={residential.id}
          owner={PhotoOwnerCaption.Residential}
          lastImageCropTimestamp={lastImageCropTimestamp}
          uploadDropzoneSlot={
            <UploadPhotosForm
              obj_id={residential.id}
              ownerValue={ImagableType.Residential}
              queryKeyForUpdate={currentPhotosByEntityIdQueryKey}
            />
          }
          onDeletePhoto={handleDeletePhoto}
          onOpenCropPhoto={handleOpenEditCropPhoto}
          handleCheckPhotosOrderChanged={handleResidentialPhotosOrderChanged}
        />
      )}
    </div>
  )
}
