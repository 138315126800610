import { UserActions, UserActionsLogListItem } from '@/shared/api/topli/TopliApi'

import { navigationRoutes } from '@/shared/routes/navigationRoutes'
import { TFunction } from 'i18next'
import { Link } from 'react-router-dom'

const linkStyles = 'text-sm font-medium text-blue hover:underline underline-offset-4'

export const generateLink = (row: UserActionsLogListItem, t: TFunction): JSX.Element | null => {
  const linkText = t('dashboard:agent_actions.open')

  const openExternalLink = (url?: string) =>
    url ? (
      <a href={url} className={linkStyles} target='_blank' rel='noopener noreferrer'>
        {linkText}
      </a>
    ) : (
      <span>-</span>
    )

  switch (row.action) {
    case UserActions.RegisterUser:
      return (
        <Link
          to={navigationRoutes.userManagement}
          state={{ name: row.user?.fullname }}
          className={linkStyles}
        >
          {linkText}
        </Link>
      )
    case UserActions.RegisterClient:
      return (
        <Link
          to={navigationRoutes.clients}
          state={{ name: row.contact?.fullname }}
          className={linkStyles}
        >
          {linkText}
        </Link>
      )
    case UserActions.CreateCollection:
    case UserActions.CreatePresentation:
      return openExternalLink(row.link || '')
    default:
      return null
  }
}
