export const navigationRoutes = {
  login: '/login',
  signUp: '/sign-up',
  dashboard: '/dashboard',
  userManagement: '/user-management',
  library: '/library',
  contacts: '/contacts',
  clients: '/clients',
  SMTPLog: '/smtp-log',
  profile: '/profile',
  addressBook: '/address-book',
  housingEstate: '/housing-estate',
  residentialById: (id: string) => `/housing-estate/${id}`,
  buildings: '/buildings',
  units: '/units',
  companies: '/companies',
  complexInfrastructure: '/complex-infrastructure',
  photos: '/photos',
  wizard: '/wizard',
}
