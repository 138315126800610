export enum LanguageCode {
  en = 'en-US',
  ru = 'ru-RU',
  tr = 'tr-TR',
  ar = 'ar-SA',
  fa = 'fa-IR',
}

export enum ShortLanguageCode {
  en = 'en',
  ru = 'ru',
  tr = 'tr',
  ar = 'ar',
  fa = 'fa',
}

export enum LanguageLabel {
  en = 'english',
  ru = 'russian',
  tr = 'turkish',
  ar = 'arabic',
  fa = 'persian',
}

export const languages = [
  {
    languageCode: ShortLanguageCode.tr,
    languageLabel: LanguageLabel.tr,
    required: true,
    rtl: false,
  },
  {
    languageCode: ShortLanguageCode.en,
    languageLabel: LanguageLabel.en,
    required: false,
    rtl: false,
  },
  {
    languageCode: ShortLanguageCode.ru,
    languageLabel: LanguageLabel.ru,
    required: false,
    rtl: false,
  },
  {
    languageCode: ShortLanguageCode.ar,
    languageLabel: LanguageLabel.ar,
    required: false,
    rtl: true,
  },
  {
    languageCode: ShortLanguageCode.fa,
    languageLabel: LanguageLabel.fa,
    required: false,
    rtl: true,
  },
]
