import * as Yup from 'yup'
import { CreateResidentialAmenityFormState } from '../model/types'
import { TFunction } from 'i18next'
import { defaultTranslationsWithoutDescriptionCreate } from '@/entities/ContentTranslation/lib/translationDefaults'
import { generateTranslationSchema } from '@/shared/lib/generateTranslationSchema'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'

export const createResidentialAmenityFormScheme = (
  t: TFunction,
  languageTabs: LanguageTabConfig[],
) => {
  return Yup.object({
    ...generateTranslationSchema(languageTabs, t, false).fields,
  })
}

export const createResidentialAmenityFormDefault: CreateResidentialAmenityFormState = {
  has_ai_translation: false,
  pic_url: '',
  translations: defaultTranslationsWithoutDescriptionCreate,
}
