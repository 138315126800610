import { topliApi } from '@/shared/api/topli/TopliApi'
import { useQuery } from '@tanstack/react-query'

export const SMTPLogQueryKey = 'SMTPLog'

export const fetchSMTPLog = async ({ limit, offset, sort, search }) => {
  const response = await topliApi.emailsLog.list({
    limit,
    offset,
    sort,
    email__icontains: search,
  })
  return response
}

export const useSMTPLog = ({ sort, limit, offset, search }) =>
  useQuery({
    queryKey: [SMTPLogQueryKey, sort, offset, limit, search || ''],
    queryFn: () => fetchSMTPLog({ sort, limit, offset, search: search || '' }),
  })
