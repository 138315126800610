import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  messageTemplatesByPageQueryKey,
  messageTemplatesQueryKey,
} from '@/entities/MessageTemplates/api/message-tempates-api'
import { topliApi } from '@/shared/api/topli/TopliApi'

export const deleteMessageTemplate = async (messageTemplateId: number) => {
  const response = await topliApi.template.delete(messageTemplateId)
  return response
}

export const useDeleteMessageTemplateApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteMessageTemplate(id),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [messageTemplatesByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [messageTemplatesQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
