import { FC } from 'react'
import { Text } from '@mantine/core'
import { Form, Formik } from 'formik'
import { useEditUnitForm } from '../lib/use-edit-unit-form'
import { editUnitFormScheme } from '../lib/consts'
import {
  CheckBoxField,
  MultiSelectField,
  NumberField,
  SelectField,
  TextField,
} from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { EditPriceFieldArray } from '@/features/Price/EditPriceFormField'
import { editPriceFormFieldDefault } from '@/features/Price/EditPriceFormField/lib/contsts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'
import LanguageTabs from '@/features/MultilingualTabs/ui/LanguageTabs'
import { generateLanguageTabsConfig } from '@/shared/lib/utils'
import { MultilingualEntityForm } from '@/features/MultilingualTabs/ui/forms/MultilingualEntityForm'

type EditUnitFormProps = {
  currentUnitId: number
  selectBuildingId?: number
  onCloseModal: () => void
}

export const EditUnitForm: FC<EditUnitFormProps> = props => {
  const { currentUnitId, selectBuildingId, onCloseModal } = props
  const { conditionOptions, accommodationTypeOptions } = useTranslatedEnums()
  const { t } = useTranslation(['unit', 'common'])
  const {
    initialValues,
    buildingsOptions,
    amenitiesOptions,
    isLoadingEditUnit,
    isLoadingBuildings,
    isLoadingAmenities,
    isLoadingUnit,
    isErrorUnit,
    submitForm,
    handleAddPriceForDelete,
    searchBuilding,
    setSearchBuilding,
    editUnitFormDefault,
    buildings,
  } = useEditUnitForm(currentUnitId, onCloseModal)

  if (isErrorUnit) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  if (isLoadingUnit) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }

  const languageTabs = generateLanguageTabsConfig(isLoadingEditUnit, MultilingualEntityForm, 'unit')

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={editUnitFormScheme(t, languageTabs, buildings)}
    >
      <Form>
        <LanguageTabs config={languageTabs} />
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <CheckBoxField
            name='activity'
            className='w-1/3'
            label={t('unit:activity')}
            disabled={isLoadingEditUnit}
          />
          <CheckBoxField
            name='hide_price'
            className='w-1/3'
            label={t('unit:hide_price')}
            disabled={isLoadingEditUnit}
          />
        </div>
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <InputAutocompleteField
            name='building_id'
            label={t('unit:building')}
            placeholder='Search for a building'
            onChange={setSearchBuilding}
            value={searchBuilding}
            className='flex flex-col w-full mb-4'
            data={buildingsOptions}
            disabled={isLoadingEditUnit || !!selectBuildingId}
            isLoading={isLoadingBuildings}
            initialValue={editUnitFormDefault.building_id}
            required
          />
          <SelectField
            name='type'
            label={t('common:library_items.accommodation_type.sing')}
            options={accommodationTypeOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditUnit}
            required
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <NumberField
            name='size'
            label={t('unit:gross_size')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditUnit}
            required
            precision={2}
            step={0.01}
            min={0}
          />
          <NumberField
            name='net_size'
            label={t('unit:net_size')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditUnit}
            required
            precision={2}
            step={0.01}
            min={0}
          />
          <NumberField
            name='lotSize'
            label={t('unit:lot_size')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditUnit}
            precision={2}
            step={0.01}
            min={0}
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <NumberField
            name='bedrooms'
            label={t('unit:bedrooms')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditUnit}
            required
            min={0}
          />
          <NumberField
            name='bathrooms'
            label={t('unit:bathrooms')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditUnit}
            required
            min={0}
          />
        </div>
        <div className='flex flex-row items-center space-x-4'>
          <NumberField
            name='level'
            label={t('unit:level')}
            className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
            disabled={isLoadingEditUnit}
            min={0}
            step={1}
          />
          <SelectField
            name='condition'
            label={t('common:library_items.condition.sing')}
            options={conditionOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditUnit}
          />
        </div>
        <TextField
          name='video_url'
          label={t('unit:video_url')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingEditUnit}
        />
        <MultiSelectField
          name='amenities'
          label={t('unit:amenities')}
          options={amenitiesOptions}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingAmenities || isLoadingEditUnit}
        />
        <div className='flex flex-col w-full mb-4'>
          <div className='text-md font-bold font-body mb-2 mt-4 text-gray-400'>
            {t('unit:commission')}
          </div>
          <NumberField
            name='fee'
            label={t('unit:commission_for_international_clients')}
            className='flex flex-col w-full mb-4'
            precision={2}
            step={0.01}
            min={0}
          />
          <NumberField
            name='fee_local'
            label={t('unit:commission_for_local_clients')}
            className='flex flex-col w-full mb-4'
            precision={2}
            step={0.01}
            min={0}
          />
          <NumberField
            name='agent_fee'
            label={t('residential:agent_commission_for_international_clients')}
            className='flex flex-col w-full mb-4'
            precision={2}
            step={0.01}
            min={0}
          />
          <NumberField
            name='agent_fee_local'
            label={t('residential:agent_commission_for_local_clients')}
            className='flex flex-col w-full mb-4'
            precision={2}
            step={0.01}
            min={0}
          />
        </div>
        <div className='flex flex-col w-full mb-4'>
          <Text component='div' className='text-md font-bold font-body mb-2 text-gray-400'>
            {t('unit:price')}
          </Text>
          <EditPriceFieldArray
            namePrice='prices'
            defaultValue={editPriceFormFieldDefault}
            onDeletePrice={handleAddPriceForDelete}
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditUnit}>
            {isLoadingEditUnit ? (
              <Loader size='xs' color='white' />
            ) : (
              t('unit:buttons.save_changes')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
