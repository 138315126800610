import { clsx, Textarea as MantineTextarea, type TextareaProps } from '@mantine/core'
import type { FC, TextareaHTMLAttributes } from 'react'

type TextareaComponentProps = TextareaProps &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    isRequired?: boolean
    isRtl?: boolean
  }

export const Textarea: FC<TextareaComponentProps> = props => {
  const { isRequired, isRtl, ...restProps } = props

  return (
    <MantineTextarea
      {...restProps}
      withAsterisk={isRequired}
      classNames={{
        root: 'leading-4',
        label: 'text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2',
        description: 'mb-2 text-sm leading-4',
        input: clsx(
          'border-gray/30 bg-white rounded-md text-black focus:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50 min-h-[8.25rem]',
          { rtl: isRtl },
        ),
      }}
    />
  )
}
