import commonEN from './en/common.json'
import commonRU from './ru/common.json'
import commonTR from './tr/common.json'
import buildingEN from './en/building.json'
import buildingRU from './ru/building.json'
import buildingTR from './tr/building.json'
import profileEN from './en/profile.json'
import profileRU from './ru/profile.json'
import profileTR from './tr/profile.json'
import amenityEN from './en/amenity.json'
import amenityRU from './ru/amenity.json'
import amenityTR from './tr/amenity.json'
import residentialEN from './en/residential.json'
import residentialRU from './ru/residential.json'
import residentialTR from './tr/residential.json'
import unitEN from './en/unit.json'
import unitRU from './ru/unit.json'
import unitTR from './tr/unit.json'
import companyEN from './en/company.json'
import companyRU from './ru/company.json'
import companyTR from './tr/company.json'
import userManagementEN from './en/userManagement.json'
import userManagementRU from './ru/userManagement.json'
import userManagementTR from './tr/userManagement.json'
import contactsEN from './en/contacts.json'
import contactsRU from './ru/contacts.json'
import contactsTR from './tr/contacts.json'
import addressEN from './en/address.json'
import addressRU from './ru/address.json'
import addressTR from './tr/address.json'
import photoEN from './en/photo.json'
import photoRU from './ru/photo.json'
import photoTR from './tr/photo.json'
import authEN from './en/auth.json'
import authRU from './ru/auth.json'
import authTR from './tr/auth.json'
import clientEN from './en/client.json'
import clientRU from './ru/client.json'
import clientTR from './tr/client.json'
import collectionEN from './en/collection.json'
import collectionRU from './ru/collection.json'
import collectionTR from './tr/collection.json'
import dashboardEN from './en/dashboard.json'
import dashboardRU from './ru/dashboard.json'
import dashboardTR from './tr/dashboard.json'
import SMTPLogEN from './en/SMTPLog.json'
import SMTPLogRU from './ru/SMTPLog.json'
import SMTPLogTR from './tr/SMTPLog.json'

export const languageResources = {
  en: {
    common: commonEN,
    building: buildingEN,
    profile: profileEN,
    amenity: amenityEN,
    residential: residentialEN,
    unit: unitEN,
    company: companyEN,
    userManagement: userManagementEN,
    contact: contactsEN,
    address: addressEN,
    photo: photoEN,
    auth: authEN,
    client: clientEN,
    collection: collectionEN,
    dashboard: dashboardEN,
    SMTPLog: SMTPLogEN,
  },
  ru: {
    common: commonRU,
    building: buildingRU,
    profile: profileRU,
    amenity: amenityRU,
    residential: residentialRU,
    unit: unitRU,
    company: companyRU,
    userManagement: userManagementRU,
    contact: contactsRU,
    address: addressRU,
    photo: photoRU,
    auth: authRU,
    client: clientRU,
    collection: collectionRU,
    dashboard: dashboardRU,
    SMTPLog: SMTPLogRU,
  },
  tr: {
    common: commonTR,
    building: buildingTR,
    profile: profileTR,
    amenity: amenityTR,
    residential: residentialTR,
    unit: unitTR,
    company: companyTR,
    userManagement: userManagementTR,
    contact: contactsTR,
    address: addressTR,
    photo: photoTR,
    auth: authTR,
    client: clientTR,
    collection: collectionTR,
    dashboard: dashboardTR,
    SMTPLog: SMTPLogTR,
  },
}
