import { FC } from 'react'
import { TextField } from '@/shared/ui/FormFields'
import { useTranslation } from 'react-i18next'

type CreateFormProps = {
  isLoading?: boolean
  languageCode: string
  isRtl?: boolean
  required?: boolean
}

export const MultilingualAmenityForm: FC<CreateFormProps> = props => {
  const { isLoading, required, isRtl = false, languageCode } = props
  const { t } = useTranslation(['aminity'])

  const isDisabled = isLoading || languageCode !== 'tr'

  return (
    <>
      <div className='flex flex-row items-center space-x-4'>
        <TextField
          name={`translations.${languageCode}.name`}
          label={t('amenity:name')}
          className={`flex flex-col w-full mb-4 ${isDisabled ? 'cursor-not-allowed' : ''}`}
          required={required}
          disabled={isDisabled} // Temporarily disabled name input for non-Turkish languages
          isRtl={isRtl}
        />
      </div>
    </>
  )
}
