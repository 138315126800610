import { Form, Formik } from 'formik'
import { FC, type ReactNode } from 'react'
import { useEditUnitInlineForm } from '../lib/use-edit-unit-inline-form'
import { Loader, ActionIcon as MantineActionButton } from '@mantine/core'
import { PenSquare, Save, X } from 'lucide-react'
import { type ShowedInlineForm } from '../model/types'
import { Building } from '@/shared/api/topli/TopliApi'
import { editUnitInlineFormScheme } from '../lib/consts'
import { useTranslation } from 'react-i18next'

type EditUnitInlineFormProps = {
  nameField: string
  selectedUnitId: number
  fieldSlot?: ReactNode
  isShowedForm: ShowedInlineForm | null
  setIsShowedForm: (form: ShowedInlineForm) => void
  currentBuilding?: Building
}

export const EditUnitInlineForm: FC<EditUnitInlineFormProps> = props => {
  const { nameField, isShowedForm, selectedUnitId, fieldSlot, setIsShowedForm, currentBuilding } =
    props

  const isVisibleForm = isShowedForm?.unitId === selectedUnitId && isShowedForm?.[nameField]

  const {
    initialValues,
    isLoadingEditUnit,
    isLoading,
    handleHideForm,
    handleShowForm,
    submitForm,
  } = useEditUnitInlineForm(selectedUnitId, nameField, setIsShowedForm, currentBuilding)

  const { t } = useTranslation()

  if (isVisibleForm) {
    return (
      <Formik
        validationSchema={editUnitInlineFormScheme(t, currentBuilding)}
        initialValues={initialValues}
        onSubmit={submitForm}
        enableReinitialize
      >
        <Form>
          <div className='flex flex-row w-full gap-x-2 items-center'>
            {isLoading ? (
              <div className='min-w-[100px] w-full'>
                <Loader size='sm' color='blue' />
              </div>
            ) : (
              fieldSlot
            )}
            <div className='flex flex-row gap-x-2'>
              <MantineActionButton
                type='button'
                className='text-red bg-red/10 hover:bg-blue hover:text-white rounded-full transition-all'
                variant='light'
                onClick={handleHideForm}
              >
                <X size={16} />
              </MantineActionButton>
              <MantineActionButton
                type='submit'
                className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
                variant='light'
              >
                {isLoadingEditUnit ? <Loader size='xs' color='white' /> : <Save size={16} />}
              </MantineActionButton>
            </div>
          </div>
        </Form>
      </Formik>
    )
  }

  return (
    <MantineActionButton
      className='text-gray-300 bg-transparent hover:bg-blue hover:text-white rounded-full transition-all'
      variant='light'
      onClick={handleShowForm}
    >
      <PenSquare size={16} />
    </MantineActionButton>
  )
}
