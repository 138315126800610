import { FC } from 'react'
import { Form, Formik } from 'formik'
import { useEditResidentalAmenityForm } from '../lib/use-edit-residential-aminity-form'
import { editResidentialAmenityFormScheme } from '../lib/consts'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { TextField } from '@/shared/ui/FormFields'
import { FontAwesome } from '@/widgets/ui/font-awesome'
import LanguageTabs from '@/features/MultilingualTabs/ui/LanguageTabs'
import { generateLanguageTabsConfig } from '@/shared/lib/utils'
import { MultilingualAmenityForm } from '@/features/MultilingualTabs/ui/forms/MultilingualAmenityForm'

type EditResidentialAmenityFormProps = {
  selectResidentialAmenityId?: number
  onCloseModal: () => void
}

export const EditResidentialAmenityForm: FC<EditResidentialAmenityFormProps> = props => {
  const { selectResidentialAmenityId, onCloseModal } = props
  const {
    initialValues,
    isLoadingEditResidentialAmenity,
    isLoadingResidentialAmenity,
    isErrorResidentialAmenity,
    submitForm,
  } = useEditResidentalAmenityForm(onCloseModal, selectResidentialAmenityId)
  const { t } = useTranslation(['common', 'amenity'])

  const languageTabs = generateLanguageTabsConfig(
    isLoadingEditResidentialAmenity,
    MultilingualAmenityForm,
    'amenity',
  )

  if (isErrorResidentialAmenity) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  if (isLoadingResidentialAmenity) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={editResidentialAmenityFormScheme(t, languageTabs)}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <LanguageTabs config={languageTabs} className='w-100 mx-[-2rem] px-8' />
          <div className='flex flex-row items-center space-x-4'>
            <TextField
              name='pic_url'
              label={t('amenity:icon_class')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingEditResidentialAmenity}
              placeholder='fa-solid fa-mug-saucer'
            />
            {values?.pic_url && (
              <div className='flex flex-col mt-1'>
                <FontAwesome iconClass={values.pic_url} />
              </div>
            )}
          </div>
          <div className='flex flex-row w-full'>
            <Button type='submit' size='md' color='base' disabled={isLoadingEditResidentialAmenity}>
              {isLoadingEditResidentialAmenity ? (
                <Loader size='xs' color='white' />
              ) : (
                t('amenity:buttons.edit_amenity')
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
