import { EditPriceFormState } from '@/features/Price/EditPriceFormField/model/types'
import { Price, TortoiseContribPydanticCreatorModelsPricesLeaf } from '../api/topli/TopliApi'

export const normalizePriceFromServer = (price: number): number => Number(price / 100)
export const normalizePriceToServer = (price: number): number => Number(price * 100)

export const transformPricesFromServer = (
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[],
  unitId: number,
): EditPriceFormState[] =>
  prices
    .filter(price => price.main)
    .map(price => ({
      id: price.id,
      price: normalizePriceFromServer(price.price),
      is_local: !!price.is_local,
      currency: price.currency || 1,
      unit_id: unitId,
      main: price.main,
    }))

export const transformPricesToServer = (
  prices: EditPriceFormState[],
  unitId: number,
): EditPriceFormState[] =>
  prices.map(price => ({
    ...price,
    unit_id: unitId,
    price: normalizePriceToServer(price.price),
    main: true,
  }))

export const hasPriceChanged = (
  updated: EditPriceFormState[],
  original: EditPriceFormState[],
): boolean => {
  const originalMap = new Map(original.filter(p => p.id).map(p => [p.id, p]))

  return updated.some(price => {
    if (!price.id) return false
    const originalPrice = originalMap.get(price.id)
    if (!originalPrice) return false

    return (
      normalizePriceToServer(originalPrice.price) !== price.price ||
      originalPrice.is_local !== price.is_local
    )
  })
}
