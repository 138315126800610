import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MRT_ColumnDef, MRT_PaginationState, MRT_SortingState } from 'mantine-react-table'
import { Badge } from '@mantine/core'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { findLabelByValue, formatDateForTable, formatDateWithoutTime } from '@/shared/lib/utils'
import { NotificationLogListItem } from '@/shared/api/topli/TopliApi'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { eventTypeColors } from './consts'
import { useNotificationLog } from '@/entities/NotificationLog'

export function useEventsTable(user_id: number) {
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { eventTypesOptions, appointmentTypeOptions } = useTranslatedEnums()
  const debouncedSearch = useDebounce(search, 500)
  const { t } = useTranslation(['userManagement', 'common'])
  const { language } = useLanguageStore()

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: notifications,
    isLoading: isLoadingNotifications,
    isError: isErrorNotifications,
    isFetching: isFetchingNotifications,
  } = useNotificationLog({
    sort: memoizedSorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    user_id,
    search: debouncedSearch,
  })

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<NotificationLogListItem>[]>(
    () => [
      {
        header: t('userManagement:client_name'),
        accessorKey: 'client_name',
        Cell: ({ row }) =>
          row.original.client_name ? (
            <div className='text-sm font-semibold'>{row.original.client_name}</div>
          ) : (
            '-'
          ),
      },
      {
        header: t('common:library_items.event_type.sing'),
        accessorKey: 'event_type',
        Cell: ({ row }) => (
          <Badge size='sm' color={eventTypeColors[row.original.event_type]}>
            {findLabelByValue(eventTypesOptions, row.original.event_type)}
          </Badge>
        ),
      },
      {
        header: t('userManagement:object_link'),
        accessorKey: 'object_link',
        enableSorting: false,
        Cell: ({ row }) => (
          <a
            href={row.original.object_link}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 hover:underline'
          >
            {t('openLink')}
          </a>
        ),
      },
      {
        header: t('userManagement:appointment_date'),
        accessorKey: 'appointment_date',
        Cell: ({ row }) =>
          row.original.appointment_date
            ? formatDateWithoutTime(row.original.appointment_date, language)
            : '-',
      },
      {
        header: t('common:library_items.appointment_type.sing'),
        accessorKey: 'appointment_type',
        Cell: ({ row }) =>
          row.original.appointment_type ? (
            <Badge size='sm'>
              {findLabelByValue(appointmentTypeOptions, row.original.appointment_type)}
            </Badge>
          ) : (
            '-'
          ),
      },
      {
        header: t('userManagement:created_at'),
        accessorKey: 'created_at',
        Cell: ({ row }) => formatDateForTable(row.original.created_at, language),
      },
    ],
    [],
  )

  return {
    fetchedNotifications: notifications?.data ?? [],
    totalRowCount: notifications?.total ?? 0,
    columns,
    pagination,
    isErrorNotifications,
    isFetchingNotifications,
    isLoadingNotifications,
    sorting,
    setPagination,
    setSorting,
    handleSearchChange,
  }
}
