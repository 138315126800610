import { topliApi } from '@/shared/api/topli/TopliApi'
import { useQuery } from '@tanstack/react-query'

export const notificationLogQueryKey = 'notificationLog'

export const getNotificationLog = async ({ limit, offset, sort, search, user_id }) => {
  const response = await topliApi.notificationLog.list({
    limit,
    offset,
    sort,
    user_id,
    client_name__icontains: search,
  })
  return response
}

export const useNotificationLog = ({ sort, limit, offset, user_id, search }) =>
  useQuery({
    queryKey: [notificationLogQueryKey, sort, offset, limit, user_id, search || ''],
    queryFn: () => getNotificationLog({ sort, limit, offset, user_id, search: search || '' }),
  })
