import { CreateBuildingFormState } from '../model/types'
import { ParkingType, Type } from '@/shared/api/topli/TopliApi'
import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { generateTranslationSchema } from '@/shared/lib/generateTranslationSchema'
import { defaultTranslationsCreate } from '@/entities/ContentTranslation/lib/translationDefaults'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'

export const createBuildingFormDefault: CreateBuildingFormState = {
  activity: true,
  polygon: [],
  delivery_date: '',
  done: false,
  hide_price: false,
  has_ai_translation: false,
  area: 0,
  unitcount: 0,
  car_unitcount: 0,
  type: [],
  parking_type: null,
  nol: 0,
  residential_id: 0,
  address_id: 0,
  translations: defaultTranslationsCreate,
}

export const createBuildingFormSchema = (t: TFunction, languageTabs: LanguageTabConfig[]) => {
  return Yup.object().shape({
    activity: Yup.boolean(),
    done: Yup.boolean(),
    hide_price: Yup.boolean(),
    residential_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    area: Yup.number().min(-2147483648).max(2147483647),
    polygon: Yup.array(Yup.array(Yup.number())),
    unitcount: Yup.number().min(0, t('common:validation_messages.min', { count: 0 })),
    car_unitcount: Yup.number(),
    type: Yup.array()
      .of(
        Yup.mixed()
          .oneOf(Object.values(Type) as number[], t('common:validation_messages.invalid_value'))
          .required(t('common:validation_messages.required')),
      )
      .ensure()
      .min(1, t('common:validation_messages.required')),
    parking_type: Yup.mixed()
      .oneOf(Object.values(ParkingType) as number[], t('common:validation_messages.required'))
      .nullable(),
    nol: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .required(t('common:validation_messages.required')),
    delivery_date: Yup.date().nullable(),
    ...generateTranslationSchema(languageTabs, t, true).fields,
  })
}
