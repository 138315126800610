import { CreateTranslations, EditTranslations } from '../model/types'

export const defaultTranslationsCreate: CreateTranslations = {
  tr: { name: '', description: '', ai_translated: false },
  en: { name: '', description: '', ai_translated: false },
  ru: { name: '', description: '', ai_translated: false },
  ar: { name: '', description: '', ai_translated: false },
  fa: { name: '', description: '', ai_translated: false },
}

export const defaultTranslationsEdit: EditTranslations = {
  tr: { name: '', description: '', ai_translated: false, id: null },
  en: { name: '', description: '', ai_translated: false, id: null },
  ru: { name: '', description: '', ai_translated: false, id: null },
  ar: { name: '', description: '', ai_translated: false, id: null },
  fa: { name: '', description: '', ai_translated: false, id: null },
}

export const defaultTranslationsWithoutDescriptionCreate: CreateTranslations = {
  tr: { name: '', ai_translated: false },
  en: { name: '', ai_translated: false },
  ru: { name: '', ai_translated: false },
  ar: { name: '', ai_translated: false },
  fa: { name: '', ai_translated: false },
}

export const defaultTranslationsWithoutDescriptionEdit: EditTranslations = {
  tr: { name: '', ai_translated: false, id: null },
  en: { name: '', ai_translated: false, id: null },
  ru: { name: '', ai_translated: false, id: null },
  ar: { name: '', ai_translated: false, id: null },
  fa: { name: '', ai_translated: false, id: null },
}
