import { PenSquare } from 'lucide-react'
import { EditUnitInlineForm } from './EditUnitInlineForm'
import { ShowedInlineForm } from '../model/types'
import { ActionIcon as MantineActionButton } from '@mantine/core'
import { ReactNode } from 'react'
import { Building } from '@/shared/api/topli/TopliApi'

type EditableCellProps = {
  unitId: number
  nameField: string
  value: ReactNode
  fieldSlot: ReactNode
  currentBuilding: Building
  isShowedForm: ShowedInlineForm | null
  isVisibleForm: (unitId: number, nameField: string) => boolean
  setIsShowedForm: (form: ShowedInlineForm) => void
  className?: string
}

export const EditableCell = ({
  unitId,
  nameField,
  value,
  fieldSlot,
  isVisibleForm,
  setIsShowedForm,
  currentBuilding,
  isShowedForm,
  className,
}: EditableCellProps) => {
  const visible = isVisibleForm(unitId, nameField)

  const wrapperClass = className ?? 'flex flex-row w-full items-center justify-between gap-x-2'
  return (
    <div className={wrapperClass}>
      {visible ? (
        <EditUnitInlineForm
          nameField={nameField}
          selectedUnitId={unitId}
          currentBuilding={currentBuilding}
          isShowedForm={isShowedForm}
          setIsShowedForm={setIsShowedForm}
          fieldSlot={fieldSlot}
        />
      ) : (
        <>
          {value}
          <MantineActionButton
            className='text-gray-300 bg-transparent hover:bg-blue hover:text-white rounded-full transition-all'
            variant='light'
            onClick={() => setIsShowedForm({ unitId, [nameField]: true })}
          >
            <PenSquare size={16} />
          </MantineActionButton>
        </>
      )}
    </div>
  )
}
