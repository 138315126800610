import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import {
  ResidentialAmenitiesView,
  UnitAmenitiesView,
  EnumsView,
  PublicOfferView,
  MessageTemplatesView,
} from '@/widgets/Settings/LibraryView'

export const Library = () => {
  return (
    <>
      <BreadCrumbs />
      <PublicOfferView />
      <ResidentialAmenitiesView />
      <UnitAmenitiesView />
      <MessageTemplatesView />
      <EnumsView />
    </>
  )
}
