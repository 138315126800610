import { cn } from '@/shared/lib/utils'
import { type InputBaseProps, type InputProps, TextInput } from '@mantine/core'
import type { FC, InputHTMLAttributes, ReactNode } from 'react'

export type InputGeneratorSize = 'small' | 'medium' | 'large'

type InputGeneratorProps = InputBaseProps &
  InputProps &
  InputHTMLAttributes<HTMLInputElement> & {
    label?: ReactNode
    isOptional?: boolean
    placeholder?: string
    className?: string
    isRequired?: boolean
    sizeInput?: InputGeneratorSize
    isRtl?: boolean
  }

const inputWrapperClassName = 'flex flex-col w-full '

const inputClassName =
  'border-gray/30 bg-white text-black focus:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50'

const inputSizeClassName: Record<InputGeneratorSize, string> = {
  small: 'min-h-[1.875rem] rounded-md',
  medium: 'min-h-[2.5rem] rounded-md',
  large: 'min-h-[2.75rem] rounded-md',
}

export const InputGenerator: FC<InputGeneratorProps> = ({ isRequired, ...props }) => {
  const { placeholder, value, onChange, error, sizeInput = 'medium', isRtl, ...restProps } = props

  const directionStyle = isRtl ? 'rtl' : 'ltr'

  const mergedInputGeneratorClassName = cn(
    inputClassName,
    inputSizeClassName[sizeInput],
    directionStyle,
  )

  return (
    <div className={inputWrapperClassName}>
      <TextInput
        {...restProps}
        withAsterisk={isRequired}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={onChange}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        classNames={{
          root: 'leading-4 text-left',
          input: mergedInputGeneratorClassName,
          label: 'text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2',
          error: 'mt-1',
          description: 'text-black/50',
        }}
        error={error}
      />
    </div>
  )
}
