import { useTranslation } from 'react-i18next'
import {
  CompanyType,
  Purpose,
  Currency,
  Class,
  Type,
  Condition,
  UserRole,
  ParkingType,
  PhotoTag,
  AuthUserStatus,
  ContactTitle,
  OrderStatus,
  EventTypes,
  AppointmentType,
  ClientType,
  UserActions,
} from '@/shared/api/topli/generated/Api'
import { TemplateNames } from '../lib/constants'
import { t } from 'i18next'

enum EmailStatus {
  Delivered = 1,
  Read = 2,
}

function useEnumOptions<T extends object>(enumObject: T, categoryKey: string) {
  const { t } = useTranslation(['common'])

  return Object.entries(enumObject)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      value: value,
      label: t(`common:library_items.${categoryKey}.items.${key}`),
    }))
}

export function useTranslatedEnums() {
  const purposeOptions = useEnumOptions(Purpose, 'purpose')
  const parkingOptions = useEnumOptions(ParkingType, 'parking_type')
  const classOptions = useEnumOptions(Class, 'class')
  const currencyOptions = useEnumOptions(Currency, 'currency')
  const companyTypeOptions = useEnumOptions(CompanyType, 'company_type')
  const accommodationTypeOptions = useEnumOptions(Type, 'accommodation_type')
  const conditionOptions = useEnumOptions(Condition, 'condition')
  const userRoleOptions = useEnumOptions(UserRole, 'user_role')
  const userStatusOptions = useEnumOptions(AuthUserStatus, 'user_status')
  const tagOptions = useEnumOptions(PhotoTag, 'photo_tag')
  const titleOptions = useEnumOptions(ContactTitle, 'title')
  const SMTPStatusOptions = useEnumOptions(EmailStatus, 'smtp_status')
  const orderStatusOptions = useEnumOptions(OrderStatus, 'order_status')
  const templateNamesOptions = useEnumOptions(TemplateNames, 'template_name')
  const eventTypesOptions = useEnumOptions(EventTypes, 'event_type')
  const appointmentTypeOptions = useEnumOptions(AppointmentType, 'appointment_type')
  const clientTypesOptions = useEnumOptions(ClientType, 'client_type')
  const agentActionsOptions = useEnumOptions(UserActions, 'agent_action')
  const contactGenderOptions = [
    {
      value: false,
      label: t('common:library_items.gender.items.female'),
    },
    {
      value: true,
      label: t('common:library_items.gender.items.male'),
    },
  ]

  return {
    purposeOptions,
    parkingOptions,
    classOptions,
    currencyOptions,
    companyTypeOptions,
    accommodationTypeOptions,
    conditionOptions,
    userRoleOptions,
    userStatusOptions,
    tagOptions,
    titleOptions,
    contactGenderOptions,
    orderStatusOptions,
    SMTPStatusOptions,
    templateNamesOptions,
    eventTypesOptions,
    clientTypesOptions,
    appointmentTypeOptions,
    agentActionsOptions,
  }
}
