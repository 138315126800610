import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { UserListItem } from '@/shared/api/topli/TopliApi'
import { useUsersByPage } from '@/entities/User'
import { Badge } from '@mantine/core'
import {
  convertPhoneNumberToString,
  findLabelByValue,
  formatDateForTable,
} from '@/shared/lib/utils'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { useLocation } from 'react-router-dom'

export const useUsersTable = () => {
  const location = useLocation()
  const [search, setSearch] = useState(location?.state?.name || '')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })

  const { userRoleOptions, userStatusOptions } = useTranslatedEnums()
  const { t } = useTranslation(['userManagement', 'common'])
  const { language } = useLanguageStore()
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    isFetching: isFetchingUsers,
  } = useUsersByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    debouncedSearch,
  )

  const fetchedUsers = users?.data ?? []
  const totalRowCount = users?.total ?? 0

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  useEffect(() => {
    if (location.state?.name) {
      setSearch(location.state.name || '')
    }
  }, [location.state])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<UserListItem>[]>(
    () => [
      {
        accessorKey: 'created_at',
        header: t('userManagement:registration_date'),
        Cell: ({ cell }) => formatDateForTable(cell.getValue() as string, language),
      },
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'fullname',
        header: t('userManagement:username'),
        Cell: ({ row }) => <div className='text-sm font-semibold'>{row.original.fullname}</div>,
      },
      {
        accessorKey: 'email',
        header: t('userManagement:email'),
      },
      {
        accessorKey: 'phone',
        header: t('userManagement:phone'),
        enableSorting: false,
        Cell: ({ row }) => (
          <div className='text-sm'>{convertPhoneNumberToString(row.original.phone ?? null)}</div>
        ),
      },
      {
        accessorKey: 'role',
        header: t('common:library_items.user_role.sing'),
        Cell: ({ row }) => {
          return (
            <Badge size='sm'>{findLabelByValue(userRoleOptions, row.original.role ?? 4)}</Badge>
          )
        },
      },
      {
        accessorKey: 'status',
        header: t('common:library_items.user_status.sing'),
        Cell: ({ row }) => {
          return (
            <Badge size='sm'>{findLabelByValue(userStatusOptions, row.original.status ?? 4)}</Badge>
          )
        },
      },
    ],
    [language],
  )
  return {
    search,
    pagination,
    fetchedUsers,
    totalRowCount,
    columns,
    isErrorUsers,
    isFetchingUsers,
    isLoadingUsers,
    sorting,
    setPagination,
    setSorting,
    handleSearchChange,
  }
}
