export enum TemplateConstants {
  RecoveryCode = 'recovery_code',
  ClientName = 'client_name',
  AgentName = 'agent_name',
  Date = 'date',
  Time = 'time',
  ObjectName = 'object_name',
  ObjectLink = 'object_link',
  AppointmentType = 'appointment_type',
  AppointmentDate = 'appointment_date',
  Message = 'message',
  Subject = 'subject',
  Phone = 'phone',
  Email = 'email',
}
