import { Group, Paper, Skeleton } from '@mantine/core'

export const PlatformActivitySkeleton = () =>
  Array.from({ length: 3 }).map((_, index) => (
    <Paper
      key={`skeleton-${index}`}
      className='rounded-[1.25rem]'
      p='xl'
      radius='md'
      style={{ height: '150px' }}
    >
      <Group position='apart' align='center'>
        <div style={{ flex: 1 }}>
          <Skeleton height={24} width='50%' radius='sm' />
          <Skeleton height={24} mt='xs' width='70%' radius='sm' />
        </div>
      </Group>
      <Skeleton height={12} mt='md' width='100%' radius='sm' />
    </Paper>
  ))
