import {
  MantineReactTable,
  useMantineReactTable,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
} from 'mantine-react-table'
import { useTranslation } from 'react-i18next'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'
import { useAgentActionsTable } from '../../lib/use-agent-actions-table'
import { Title } from '@mantine/core'

export const AgentActionsTable = () => {
  const {
    pagination,
    sorting,
    columns,
    fetchedAgentActions,
    totalRowCount,
    isErrorAgentActions,
    isFetchingAgentActions,
    isLoadingAgentActions,
    setSorting,
    setPagination,
    handleSearchChange,
  } = useAgentActionsTable()

  const { t, i18n } = useTranslation()
  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedAgentActions,
    enableStickyHeader: false,
    manualPagination: true,
    manualSorting: true,
    enableRowActions: false,
    enableColumnActions: false,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    rowCount: totalRowCount,
    mantineSearchTextInputProps: {
      placeholder: t('contact:search'),
    },
    localization,
    onGlobalFilterChange: handleSearchChange,
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row items-center justify-between mb-2'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2'>
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
          </div>
        </div>
      )
    },
    mantineToolbarAlertBannerProps: isErrorAgentActions
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      isLoading: isLoadingAgentActions,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorAgentActions,
      showProgressBars: isFetchingAgentActions,
    },
  })

  return (
    <div className='flex flex-col mb-5 w-full mx-0 bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <Title className='mb-[1.25rem]' order={4}>
        {t('common:library_items.agent_action.pl')}
      </Title>
      <MantineReactTable table={table} />
    </div>
  )
}
