import * as Yup from 'yup'
import { CreateUnitAmenityFormState } from '../model/types'
import { TFunction } from 'i18next'
import { defaultTranslationsWithoutDescriptionCreate } from '@/entities/ContentTranslation/lib/translationDefaults'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'
import { generateTranslationSchema } from '@/shared/lib/generateTranslationSchema'

export const createUnitAmenityFormScheme = (t: TFunction, languageTabs: LanguageTabConfig[]) => {
  return Yup.object({
    ...generateTranslationSchema(languageTabs, t, false).fields,
  })
}

export const createUnitAmenityFormDefault: CreateUnitAmenityFormState = {
  has_ai_translation: false,
  pic_url: '',
  translations: defaultTranslationsWithoutDescriptionCreate,
}
