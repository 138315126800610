import { Field, FieldProps, Form, Formik } from 'formik'
import { useCreateResidentialForm } from '../lib/use-create-residential-form'
import { FC } from 'react'
import {
  CheckBoxField,
  MultiSelectField,
  SelectField,
  TextField,
  DateField,
  NumberField,
} from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { FieldFormPolygonDrawing } from './FieldFormPolygonDrawing'
import { createResidentialFormSchema } from '../lib/consts'
import { Loader } from '@mantine/core'
import { FormSelectOnMap } from '@/shared/ui/FormSelectOnMap/FormSelectOnMap'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { InputAutocompleteField } from '@/shared/ui/Input/InputAutocompleteField'
import LanguageTabs from '@/features/MultilingualTabs/ui/LanguageTabs'
import { MultilingualEntityForm } from '@/features/MultilingualTabs/ui/forms/MultilingualEntityForm'
import { generateLanguageTabsConfig } from '@/shared/lib/utils'

type CreateResidentialFormProps = {
  onCloseModal: () => void
}

export const CreateResidentialForm: FC<CreateResidentialFormProps> = props => {
  const { onCloseModal } = props
  const { purposeOptions, classOptions, parkingOptions } = useTranslatedEnums()
  const { t } = useTranslation(['residential', 'common'])

  const {
    initialValues,
    companiesOptions,
    managerOptions,
    contentManagerOptions,
    addresses,
    addressOptions,
    amenitiesOptions,
    isLoadingCompanies,
    isLoadingManagers,
    isLoadingContentManagers,
    isLoadingAddresses,
    isLoadingCreateResidential,
    isLoadingAmenities,
    isLoadingCreateAddress,
    coords,
    isOpen,
    submitForm,
    setIsOpen,
    handleSetLoc,
    handleSaveAddress,
    handleCreateNewAddress,
    searchCompany,
    setSearchCompany,
    createResidentialFormDefault,
  } = useCreateResidentialForm(onCloseModal)

  const languageTabs = generateLanguageTabsConfig(
    isLoadingCreateResidential,
    MultilingualEntityForm,
    'residential',
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createResidentialFormSchema(t, languageTabs)}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <LanguageTabs config={languageTabs} />
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <CheckBoxField
              name='activity'
              className='w-1/3'
              label={t('residential:activity')}
              disabled={isLoadingCreateResidential}
            />
            <CheckBoxField
              name='done'
              className='w-1/3'
              label={t('residential:done')}
              disabled={isLoadingCreateResidential}
            />
            <CheckBoxField
              name='hide_price'
              className='w-1/3'
              label={t('residential:hide_price')}
              disabled={isLoadingCreateResidential}
            />
          </div>
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <CheckBoxField
              name='citizenship'
              className='w-1/3'
              label={t('residential:citizenship')}
              disabled={isLoadingCreateResidential}
            />
            <CheckBoxField
              name='residency_supported'
              className='w-1/3'
              label={t('residential:residence_permit')}
              disabled={isLoadingCreateResidential}
            />
          </div>
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <CheckBoxField
              name='local_homepage'
              className='w-full'
              label={t('residential:local_homepage')}
              disabled={isLoadingCreateResidential}
            />
          </div>
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <CheckBoxField
              name='international_homepage'
              className='w-full'
              label={t('residential:international_homepage')}
              disabled={isLoadingCreateResidential}
            />
          </div>
          <div className='flex flex-col w-full mb-4'>
            <div className='text-md font-bold font-body mb-2 text-gray-400'>
              {t('residential:main')}
            </div>
            <div className='flex flex-col w-full gap-y-2 mb-4'>
              <div className='flex flex-row items-end space-x-4 mb-2'>
                <SelectField
                  name='address_id'
                  label={t('residential:address')}
                  isSearchable
                  disabled={isLoadingAddresses || isLoadingCreateResidential}
                  options={addressOptions}
                  className='flex flex-col w-full'
                  required
                  clearable
                />
                <Field>
                  {({ form: { setFieldValue } }: FieldProps) => (
                    <FormSelectOnMap
                      coords={coords}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      onSelectCoords={handleSetLoc}
                      withoutPin
                      isLoading={isLoadingCreateAddress}
                      onSave={() => handleSaveAddress(setFieldValue)}
                    />
                  )}
                </Field>
              </div>
              <div className='flex flex-row items-center'>
                <div className='text-sm font-medium mr-2'>
                  {t('residential:highlight_residential')}:
                </div>
              </div>
              <div className='flex flex-row items-center mb-2'>
                <FieldFormPolygonDrawing
                  name='area'
                  addresses={addresses}
                  disabled={!values.address_id}
                  className='flex flex-row items-center gap-4'
                />
              </div>
              <Button
                type='button'
                isLinkButton
                variant='link'
                color='blue'
                size='link'
                onClick={handleCreateNewAddress}
                className='underline hover:no-underline underline-offset-4'
              >
                {t('residential:create_new_address')}
              </Button>
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <DateField
                name='delivery_date'
                label={t('residential:delivery_date')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <NumberField
                name='title_deed'
                label={t('residential:title_deed')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
                precision={0}
                step={1}
                min={0}
              />
              <NumberField
                name='vat'
                label={t('residential:vat')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
                precision={0}
                step={1}
                min={0}
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <NumberField
                name='downpayment'
                label={t('residential:downpayment')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
                precision={0}
                step={1}
                min={0}
              />
              <NumberField
                name='duration'
                label={t('residential:duration_month')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
                precision={0}
                step={1}
                min={0}
              />
            </div>
          </div>
          <div className='flex flex-col w-full mb-4'>
            <div className='text-md font-bold font-body mb-2 text-gray-400'>
              {t('residential:commission')}
            </div>
            <NumberField
              name='fee'
              label={t('residential:commission_for_international_clients')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateResidential}
              precision={2}
              step={0.01}
              min={0}
            />
            <NumberField
              name='fee_local'
              label={t('residential:commission_for_local_clients')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateResidential}
              precision={2}
              step={0.01}
              min={0}
            />

            <NumberField
              name='agent_fee'
              label={t('residential:agent_commission_for_international_clients')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateResidential}
              precision={2}
              step={0.01}
              min={0}
            />
            <NumberField
              name='agent_fee_local'
              label={t('residential:agent_commission_for_local_clients')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateResidential}
              precision={2}
              step={0.01}
              min={0}
            />
          </div>
          <div className='flex flex-col w-full mb-4'>
            <div className='text-md font-bold font-body mb-2 text-gray-400'>
              {t('residential:features')}
            </div>
            <div className='flex flex-row items-start space-x-4'>
              <SelectField
                name='clas'
                label={t('common:library_items.class.sing')}
                options={classOptions}
                isSearchable
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
              />
              <MultiSelectField
                name='purpose'
                label={t('common:library_items.purpose.sing')}
                options={purposeOptions}
                isSearchable
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
              />
            </div>
            <div className='flex flex-row items-start space-x-4'>
              <NumberField
                name='unitcount'
                label={t('residential:unit_count')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
                min={0}
                required
              />
              <NumberField
                name='car_unitcount'
                label={t('residential:car_unit_count')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
                min={0}
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <SelectField
                name='parking_type'
                label={t('common:library_items.parking_type.sing')}
                isSearchable
                options={parkingOptions}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingCreateResidential}
                required
              />
            </div>
            <MultiSelectField
              name='amenities'
              label={t('residential:amenities')}
              options={amenitiesOptions}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingAmenities || isLoadingCreateResidential}
            />
          </div>

          <div className='flex flex-col w-full mb-4'>
            <div className='text-md font-bold font-body mb-2 text-gray-400'>
              {t('residential:extras')}
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <InputAutocompleteField
                name='company_id'
                label={t('residential:company')}
                onChange={setSearchCompany}
                value={searchCompany}
                className='flex flex-col w-full mb-4'
                data={companiesOptions}
                disabled={isLoadingCreateResidential}
                isLoading={isLoadingCompanies}
                initialValue={createResidentialFormDefault.company_id}
                required
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <SelectField
                name='manager_id'
                label={t('residential:manager')}
                isSearchable
                disabled={isLoadingManagers || isLoadingCreateResidential}
                options={managerOptions}
                className='flex flex-col w-full mb-4'
                required
              />
              <SelectField
                name='content_manager_id'
                label={t('residential:content_manager')}
                isSearchable
                disabled={isLoadingContentManagers || isLoadingCreateResidential}
                options={contentManagerOptions}
                className='flex flex-col w-full mb-4'
                required
              />
            </div>
          </div>

          <div className='flex flex-row items-center space-x-4'>
            <TextField
              name='googledrive_url'
              label={t('residential:google_drive_url')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateResidential}
            />
            <TextField
              name='googledrive_company_url'
              label={t('residential:google_drive_company_url')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingCreateResidential}
            />
          </div>
          <TextField
            name='project_url'
            label={t('residential:project_url')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateResidential}
          />
          <div className='flex flex-row w-full'>
            <Button type='submit' size='md' color='base' disabled={isLoadingCreateResidential}>
              {isLoadingCreateResidential ? (
                <Loader size='xs' color='white' />
              ) : (
                t('residential:buttons.add_residential')
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
