import { transformOptionsToTableData } from '@/shared/lib/utils'
import { Title } from '@mantine/core'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import EnumsTable from './EnumsTable'
import { useTranslation } from 'react-i18next'

export const EnumsView = () => {
  const { t } = useTranslation(['common'])
  const {
    purposeOptions,
    parkingOptions,
    classOptions,
    currencyOptions,
    companyTypeOptions,
    accommodationTypeOptions,
    conditionOptions,
    userRoleOptions,
    userStatusOptions,
    tagOptions,
    titleOptions,
    orderStatusOptions,
    eventTypesOptions,
    appointmentTypeOptions,
    clientTypesOptions,
    agentActionsOptions,
  } = useTranslatedEnums()

  const enumData = [
    { options: classOptions, title: t('common:library_items.class.pl') },
    { options: conditionOptions, title: t('common:library_items.condition.pl') },
    { options: parkingOptions, title: t('common:library_items.parking_type.pl') },
    { options: titleOptions, title: t('common:library_items.title.pl') },
    { options: userStatusOptions, title: t('common:library_items.user_status.pl') },
    { options: userRoleOptions, title: t('common:library_items.user_role.pl') },
    { options: companyTypeOptions, title: t('common:library_items.company_type.pl') },
    { options: currencyOptions, title: t('common:library_items.currency.pl') },
    { options: purposeOptions, title: t('common:library_items.purpose.pl') },
    { options: accommodationTypeOptions, title: t('common:library_items.accommodation_type.pl') },
    { options: tagOptions, title: t('common:library_items.photo_tag.pl') },
    { options: orderStatusOptions, title: t('common:library_items.order_status.pl') },
    { options: eventTypesOptions, title: t('common:library_items.event_type.pl') },
    { options: appointmentTypeOptions, title: t('common:library_items.appointment_type.pl') },
    { options: clientTypesOptions, title: t('common:library_items.client_type.pl') },
    { options: agentActionsOptions, title: t('common:library_items.agent_action.pl') },
  ]

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
      {enumData.map(({ options, title }) => (
        <div key={title} className='flex flex-col bg-white rounded-[1.25rem] shadow-navbar p-4'>
          <Title order={4}>{title}</Title>
          <EnumsTable data={transformOptionsToTableData(options)} />
        </div>
      ))}
    </div>
  )
}
