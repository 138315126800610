import { DeletePriceBody, Price, topliApi } from '@/shared/api/topli/TopliApi'
import { EditPriceFormState } from '../model/types'
import { hasPriceChanged } from '@/shared/helpers/priceHelpers'

export const updatePrices = async (
  prices: EditPriceFormState[],
  originalPrices: EditPriceFormState[],
) => {
  const oldPrices = prices.filter(price => price.id)
  const newPrices = prices.filter(price => !price.id && price.price > 0)

  let responseNewPrices
  let responseOldPrices

  if (hasPriceChanged(oldPrices, originalPrices)) {
    responseOldPrices = await topliApi.prices.update({
      prices: oldPrices as Price[],
    })
  }

  if (newPrices.length > 0) {
    responseNewPrices = await topliApi.prices.addPrices({
      prices: newPrices as Price[],
    })
  }

  return { responseOldPrices, responseNewPrices }
}

export const deletePrice = async (data: DeletePriceBody) => {
  const response = await topliApi.prices.delete(data)
  return response
}
