import { topliApi } from '@/shared/api/topli/TopliApi'
import { useQuery } from '@tanstack/react-query'

export const agentActionsByPageQueryKey = 'agentActionsByPage'

export const fetchAgentActionsByPage = async (
  sorting: string | null,
  limit: number,
  offset: number,
  search: string,
) => {
  const response = await topliApi.agentActions.list({
    limit,
    offset,
    sort: sorting,
    user__fullname__icontains: search,
  })

  return response
}

export const useAgentActionsByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  search: string,
) =>
  useQuery({
    queryKey: [agentActionsByPageQueryKey, sorting, page, limit, search || ''],
    queryFn: () => fetchAgentActionsByPage(sorting, limit, page, search),
  })
