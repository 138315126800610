import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { SMTPLogView } from '@/widgets/Settings/SMTPLogs'

export const SMTPLogPage = () => {
  return (
    <>
      <BreadCrumbs />
      <SMTPLogView />
    </>
  )
}
