import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { PlatformActivityStats } from './PlatformActivityStats/PlatformActivityStats'
import { AgentActionsTable } from './AgentActions/AgentActions'

export const DashboardView = () => {
  return (
    <>
      <BreadCrumbs />
      <PlatformActivityStats />
      <AgentActionsTable />
    </>
  )
}
