export const statusColors = {
  1: 'orange',
  2: 'violet',
  3: 'blue',
  4: 'pink',
  5: 'red',
  6: 'yellow',
  7: 'green',
  8: 'cyan',
  9: 'lime',
}
