import { FieldArray } from 'formik'
import { EditPriceFormField } from './EditPriceFornField'
import { Button } from '@/shared/ui/Button/Button'
import { Plus } from 'lucide-react'
import { FC } from 'react'
import { Currency, DeletePriceBody } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'

type EditPriceFieldArrayProps = {
  namePrice: string
  defaultValue: any
  onDeletePrice: (data: DeletePriceBody) => void
}

export const EditPriceFieldArray: FC<EditPriceFieldArrayProps> = props => {
  const { namePrice, defaultValue, onDeletePrice } = props
  const { t } = useTranslation(['unit'])

  const handlePushPrice = (cb: (price: unknown) => void, values: any[]) => {
    if (values.length === 2) return
    const { id, ...restValues } = defaultValue
    if (values.length === 1) {
      return cb({ ...defaultValue, is_local: !values[0].is_local })
    }
    cb(
      values.length === 0
        ? { id: null, currency: Currency.USD, is_local: false, price: 0, unit_id: null }
        : { id: null, ...restValues },
    )
  }

  const handleRemovePrice = (
    index: number,
    cb: (price: number) => void,
    unit_id: number | null,
    is_local: boolean,
  ) => {
    if (unit_id) onDeletePrice({ unit_id, is_local })
    return cb(index)
  }

  return (
    <FieldArray name={namePrice}>
      {({ push, remove, form: { values, errors } }) => (
        <div className='flex flex-col w-full'>
          {values.prices &&
            values.prices?.map((item: any, index: number) => (
              <EditPriceFormField
                key={index}
                nameCurrency={`${namePrice}.${index}.currency`}
                nameIsLocal={`${namePrice}.${index}.is_local`}
                namePrice={`${namePrice}.${index}.price`}
                currentPriceIndex={index}
                onRemovePrice={() =>
                  values.prices.length > 0
                    ? handleRemovePrice(index, remove, item.unit_id, item.is_local)
                    : null
                }
              />
            ))}
          <span className='text-[#fa5252] text-sm mb-3'>{errors.prices && errors.prices[1]}</span>
          <Button
            type='button'
            variant='subtle'
            color='blue'
            fullWidth
            leftIcon={<Plus />}
            size='md'
            onClick={() => handlePushPrice(push, values.prices)}
          >
            {t('unit:add_price')}
          </Button>
        </div>
      )}
    </FieldArray>
  )
}
