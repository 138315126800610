import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { ResidentialListItem } from '@/shared/api/topli/TopliApi'
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox'
import ManagerCell from '../ui/ResidentialTable/ManagerCell'
import CompanyCell from '../ui/ResidentialTable/CompanyCell'
import { useResidentialsByPage } from '@/entities/Residential'
import { Badge, ActionIcon as MantineActionButton, Text } from '@mantine/core'
import { Camera, CameraOff, LinkIcon, MapPin } from 'lucide-react'
import { BuildingsCell } from '../ui/ResidentialTable/BuildingsCell'
import { Link } from 'react-router-dom'
import { navigationRoutes } from '@/shared/routes/navigationRoutes'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { formatDateWithoutTime, getCurrentTranslation } from '@/shared/lib/utils'
import { useDebounce } from '@/shared/lib/useDebounce'

export const useResidentialTable = (
  onOpenFullDescription: (row: ResidentialListItem) => void,
  onOpenPhotos: (row: ResidentialListItem) => void,
  onOpenMapModal: (row: ResidentialListItem) => void,
) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { language } = useLanguageStore()
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const { t } = useTranslation(['residential'])
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: residentials,
    isLoading: isLoadingResidentials,
    isError: isErrorResidentials,
    isFetching: isFetchingResidentials,
  } = useResidentialsByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    debouncedSearch,
    language,
  )

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const fetchedResidentials = useMemo(() => residentials?.data ?? [], [residentials])
  const totalRowCount = residentials?.total ?? 0

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<ResidentialListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 100,
      },
      {
        accessorKey: 'name',
        header: t('residential:name'),
        Cell: ({ row }) => (
          <Link
            to={navigationRoutes.residentialById(String(row.original.id))}
            className='text-sm font-medium text-blue hover:underline underline-offset-4'
          >
            {getCurrentTranslation(row.original, language, 'name')}
          </Link>
        ),
      },
      {
        accessorKey: 'photos',
        header: t('residential:photos'),
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.photos ? (
            <MantineActionButton variant='light' onClick={() => onOpenPhotos(row.original)}>
              <Camera />
            </MantineActionButton>
          ) : (
            <CameraOff color='#c5cad0' />
          ),
      },
      {
        accessorKey: 'description',
        header: t('residential:description'),
        size: 380,
        Cell: ({ row }) =>
          row.original.description ? (
            <>
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col w-full mb-6 text-sm font-body',
                }}
              >
                {getCurrentTranslation(row.original, language, 'description')}
              </Text>
              {row.original.description.length > 215 && (
                <button
                  type='button'
                  className='bg-transparent border-none text-blue px-0'
                  onClick={() => onOpenFullDescription(row.original)}
                >
                  {t('residential:show_more')}
                </button>
              )}
            </>
          ) : (
            '–'
          ),
      },
      {
        accessorKey: 'buildings',
        header: t('residential:buildings'),
        size: 280,
        Cell: ({ row }) => <BuildingsCell buildings={row.original.buildings} />,
      },
      {
        accessorKey: 'delivery_date',
        size: 300,
        header: t('residential:delivery_date'),
        Cell: ({ row }) => (
          <div>{formatDateWithoutTime(row.original.delivery_date || null, language)}</div>
        ),
      },
      {
        accessorKey: 'fee',
        size: 280,
        header: `${t('residential:commission_for_international_clients')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.fee != null ? row.original.fee : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'fee_local',
        size: 280,
        header: `${t('residential:commission_for_local_clients')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.fee != null ? row.original.fee_local : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'agent_fee',
        size: 280,
        header: `${t('residential:agent_commission_for_international_clients')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.agent_fee != null ? row.original.agent_fee : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'agent_fee_local',
        size: 280,
        header: `${t('residential:agent_commission_for_local_clients')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.agent_fee_local != null ? row.original.agent_fee_local : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'title_deed',
        size: 280,
        header: `${t('residential:title_deed')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.title_deed != null ? row.original.title_deed : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'vat',
        size: 280,
        header: `${t('residential:vat')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.vat != null ? row.original.vat : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'downpayment',
        size: 280,
        header: `${t('residential:downpayment')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.downpayment != null ? row.original.downpayment : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'duration',
        size: 280,
        header: `${t('residential:duration_month')}`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.duration != null ? row.original.duration : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'video_url',
        header: t('residential:video_url'),
        Cell: ({ row }) => (
          <a target={'_blank'} href={row.original.video_url ?? undefined}>
            {t('residential:link')}
          </a>
        ),
      },
      {
        accessorKey: 'manager',
        header: t('residential:manager'),
        Cell: ({ row }) => <ManagerCell manager={row.original.manager} />,
      },
      {
        accessorKey: 'company',
        header: t('residential:company'),
        Cell: ({ row }) => <CompanyCell company={row.original.company} />,
      },
      {
        accessorKey: 'amenities',
        header: t('residential:amenities'),
        size: 300,
        Cell: ({ row }) => (
          <div className='flex flex-row flex-wrap gap-1 w-full'>
            {row.original.amenities.length > 0
              ? row.original.amenities.map(amenity => (
                  <Badge key={amenity.id} size='sm' color='gold'>
                    {getCurrentTranslation(amenity, language, 'name')}
                  </Badge>
                ))
              : '–'}
          </div>
        ),
      },
      {
        accessorKey: 'project_url',
        header: t('residential:project_url'),
        Cell: ({ row }) =>
          row.original.project_url && (
            <a href={row.original.project_url} className='text-blue underline' target='_blank'>
              <LinkIcon />
            </a>
          ),
      },
      {
        accessorKey: 'address',
        header: t('residential:address'),
        size: 320,
        Cell: ({ row }) => {
          return (
            <div className='flex flex-row items-center w-full space-x-2'>
              <MantineActionButton
                variant='light'
                className='text-blue'
                onClick={() => onOpenMapModal(row.original)}
              >
                <MapPin size={20} />
              </MantineActionButton>
              <div className='text-sm text-black'>{row.original.address.formatted}</div>
            </div>
          )
        },
      },
      {
        accessorKey: 'activity',
        header: t('residential:activity'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.activity} readOnly />,
      },
      {
        accessorKey: 'done',
        header: t('residential:done'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.done} readOnly />,
      },
      {
        accessorKey: 'hide_price',
        header: t('residential:hide_price'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.hide_price} readOnly />,
      },
      {
        accessorKey: 'citizenship',
        header: t('residential:citizenship'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.citizenship} readOnly />,
      },
      {
        accessorKey: 'residency_supported',
        header: t('residential:residence_permit'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.residency_supported} readOnly />,
      },
      {
        accessorKey: 'local_homepage',
        header: t('residential:local_homepage'),
        size: 300,
        Cell: ({ row }) => <Checkbox checked={!!row.original.local_homepage} readOnly />,
      },
      {
        accessorKey: 'international_homepage',
        header: t('residential:international_homepage'),
        size: 300,
        Cell: ({ row }) => <Checkbox checked={!!row.original.international_homepage} readOnly />,
      },
      {
        accessorKey: 'ai_translations',
        header: t('residential:machine_translation'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      },
    ],
    [onOpenFullDescription, onOpenMapModal, onOpenPhotos, language],
  )
  return {
    pagination,
    fetchedResidentials,
    totalRowCount,
    columns,
    isErrorResidentials,
    isFetchingResidentials,
    isLoadingResidentials,
    sorting,
    setPagination,
    setSorting,
    handleSearchChange,
  }
}
