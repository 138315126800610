import { CreateTranslations } from '@/entities/ContentTranslation/model/types'
import { LanguageCode } from '@/features/MultilingualTabs/model/config'

export const initializeTranslationsForCreation = (
  translations: CreateTranslations,
): CreateTranslations => {
  const primaryLang = LanguageCode.tr.split('-')[0]
  const primaryTranslationData = translations[primaryLang]

  const updatedTranslations: CreateTranslations = {} as CreateTranslations

  Object.entries(translations).forEach(([lang, currentTranslationData]) => {
    const isPrimaryLang = lang === primaryLang
    const name = primaryTranslationData.name
    const description = currentTranslationData.description || primaryTranslationData.description

    // Temporarily commented out the translation creation during cloning,
    // as the "name" field is currently disabled, and the translation will be taken from the Turkish form before submission
    // const name = currentTranslationData.name || primaryTranslationData.name

    const ai_translated =
      !isPrimaryLang &&
      ((!currentTranslationData.name && !!primaryTranslationData.name) ||
        (!currentTranslationData.description && !!primaryTranslationData.description))

    updatedTranslations[lang] = {
      ...currentTranslationData,
      name,
      description,
      ai_translated,
    }
  })

  return updatedTranslations
}
