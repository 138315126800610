import { topliApi } from '@/shared/api/topli/TopliApi'
import { useQuery } from '@tanstack/react-query'

export const platformActivityQueryKey = 'platformActivity'

export const fetchPlatformActivity = async () => {
  const response = await topliApi.dashboard.get({})
  return response
}

export const usePlatformActivity = () =>
  useQuery({
    queryKey: [platformActivityQueryKey],
    queryFn: () => fetchPlatformActivity(),
  })
