import { Class, ParkingType, Purpose } from '@/shared/api/topli/generated/Api'
import { CreateResidentialFormState } from '../model/types'
import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { defaultTranslationsCreate } from '@/entities/ContentTranslation/lib/translationDefaults'
import { generateTranslationSchema } from '@/shared/lib/generateTranslationSchema'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'

export const createResidentialFormDefault: CreateResidentialFormState = {
  activity: true,
  polygon: [],
  delivery_date: '',
  done: false,
  video_url: '',
  clas: null,
  purpose: [],
  project_url: '',
  hide_price: false,
  area: 0,
  unitcount: 0,
  fee: 0,
  fee_local: 0,
  agent_fee: 0,
  agent_fee_local: 0,
  car_unitcount: 0,
  parking_type: null,
  googledrive_url: '',
  googledrive_company_url: '',
  company_id: 0,
  content_manager_id: 0,
  manager_id: 0,
  address_id: null,
  amenities: [],
  has_ai_translation: false,
  citizenship: true,
  residency_supported: false,
  local_homepage: false,
  international_homepage: false,
  title_deed: 4,
  vat: 18,
  downpayment: 100,
  duration: 12,
  translations: defaultTranslationsCreate,
}

export const createResidentialFormSchema = (t: TFunction, languageTabs: LanguageTabConfig[]) => {
  const commissionField = Yup.number()
    .min(0, t('common:validation_messages.min', { count: 0 }))
    .max(99, t('common:validation_messages.value_must_be_less', { count: 100 }))

  return Yup.object({
    fee: commissionField,
    fee_local: commissionField,
    agent_fee: commissionField,
    agent_fee_local: commissionField,
    vat: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(99, t('common:validation_messages.value_must_be_less', { count: 100 })),
    title_deed: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(99, t('common:validation_messages.value_must_be_less', { count: 100 })),
    downpayment: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(999, t('common:validation_messages.value_must_be_less', { count: 1000 })),
    duration: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(999, t('common:validation_messages.value_must_be_less', { count: 1000 })),
    activity: Yup.boolean(),
    local_homepage: Yup.boolean(),
    international_homepage: Yup.boolean(),
    polygon: Yup.array(Yup.array(Yup.number())),
    delivery_date: Yup.date(),
    done: Yup.boolean(),
    video_url: Yup.string().max(255, t('common:validation_messages.max', { count: 225 })),
    clas: Yup.mixed()
      .oneOf(Object.values(Class) as number[], t('common:validation_messages.required'))
      .nullable(),
    purpose: Yup.array()
      .of(
        Yup.mixed().oneOf(
          Object.values(Purpose) as number[],
          t('common:validation_messages.invalid_value'),
        ),
      )
      .ensure(),
    hide_price: Yup.boolean(),
    area: Yup.number().min(0, t('common:validation_messages.min', { count: 0 })),
    unitcount: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .required(),
    car_unitcount: Yup.number(),
    parking_type: Yup.mixed()
      .oneOf(Object.values(ParkingType) as number[], t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    googledrive_url: Yup.string().max(255, t('common:validation_messages.max', { count: 225 })),
    googledrive_company_url: Yup.string().max(
      255,
      t('common:validation_messages.max', { count: 225 }),
    ),
    company_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    address_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    content_manager_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    manager_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    project_url: Yup.string()
      .max(255, t('common:validation_messages.max', { count: 225 }))
      .nullable(),
    ...generateTranslationSchema(languageTabs, t, true).fields,
  })
}
