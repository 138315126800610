import { usePlatformActivity } from '@/entities/PlatformActivity/api/platform-activity-api'
import { Group, Paper, Text, SimpleGrid } from '@mantine/core'
import { PlatformActivitySkeleton } from '../Skeletons/PlatformActivitySkeleton'
import { useTranslation } from 'react-i18next'
import { MetricsResponse } from '@/shared/api/topli/TopliApi'

const matchData = (data: MetricsResponse) => {
  return Object.entries(data).map(([key, value]) => ({
    ...value,
    title: key,
  }))
}

export const PlatformActivityStats = () => {
  const {
    data: platformActivity,
    isLoading: isLoadingPlatformActivity,
    isError: isErrorplatformActivity,
  } = usePlatformActivity()
  const { t } = useTranslation(['dashboard'])

  const formattedData = platformActivity && matchData(platformActivity)

  if (isErrorplatformActivity) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  return (
    <div className='flex flex-col md:w-full w-full gap-y-4 mb-5'>
      <div className='flex flex-row justify-between w-full'>
        <SimpleGrid
          cols={3}
          spacing='md'
          className='w-full md:grid-cols-3 sm:grid-cols-2 grid-cols-1'
        >
          {isLoadingPlatformActivity ? (
            <PlatformActivitySkeleton />
          ) : (
            formattedData &&
            formattedData.map(item => (
              <Paper
                className='rounded-[1.25rem]'
                p='xl'
                color='white'
                radius='md'
                key={item.title}
              >
                <Group>
                  <div>
                    <Text c='dimmed' tt='uppercase' fw={700} fz='xs'>
                      {t(`dashboard:${item.title}`)}
                    </Text>
                    <Text fw={700} fz='xl'>
                      {item.total.toLocaleString()}
                    </Text>
                  </div>
                </Group>
                <Text c='dimmed' fz='sm' mt='md'>
                  <Text component='span' c='teal' fw={700}>
                    {`${item.diff}% `}
                  </Text>
                  {t('dashboard:compared_7_days')}
                </Text>
              </Paper>
            ))
          )}
        </SimpleGrid>
      </div>
    </div>
  )
}
