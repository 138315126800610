import * as Yup from 'yup'
import { Building, BuildingList, Condition, Type } from '@/shared/api/topli/generated/Api'
import { EditUnitFormState } from '../model/types'
import { TFunction } from 'i18next'
import { defaultTranslationsEdit } from '@/entities/ContentTranslation/lib/translationDefaults'
import { LanguageTabConfig } from '@/features/MultilingualTabs/model/types'
import { generateTranslationSchema } from '@/shared/lib/generateTranslationSchema'

const commonValidationSchema = (t: TFunction) => {
  const commissionField = Yup.number()
    .min(0, t('common:validation_messages.min', { count: 0 }))
    .max(99, t('common:validation_messages.value_must_be_less', { count: 100 }))
    .nullable()

  return {
    prices: Yup.array(
      Yup.object().test({
        name: 'one-local-currency',
        message: t('common:validation_messages.one_local_and_one_nonlocal_price_possible'),
        test: function () {
          const array = this.parent
          const length = array.length
          const isHasLocalPrice = array.filter(item => item.is_local)
          return isHasLocalPrice.length !== 1 ? (length === 2 ? false : true) : true
        },
      }),
    ),
    type: Yup.mixed()
      .oneOf(Object.values(Type) as number[], t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    size: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .max(32767, t('common:validation_messages.max', { count: 32767 })),
    net_size: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .max(32767, t('common:validation_messages.max', { count: 32767 }))
      .required(t('common:validation_messages.required'))
      .test(
        'is-less-than-size',
        t('common:validation_messages.net_size_less_than_gross_size'),
        function (value) {
          const { size } = this.parent
          return size && value ? value < size : true
        },
      ),
    lotSize: Yup.number()
      .min(0)
      .max(32767, t('common:validation_messages.max', { count: 32767 })),
    bedrooms: Yup.number()
      .min(1, t('common:validation_messages.min', { count: 1 }))
      .max(10, t('common:validation_messages.value_must_be_less_or_equal', { count: 10 })),
    bathrooms: Yup.number()
      .min(1, t('common:validation_messages.min', { count: 1 }))
      .max(10, t('common:validation_messages.value_must_be_less_or_equal', { count: 10 })),
    condition: Yup.mixed()
      .oneOf(Object.values(Condition) as number[], t('common:validation_messages.invalid_value'))
      .nullable(),
    fee: commissionField,
    fee_local: commissionField,
    agent_fee: commissionField,
    agent_fee_local: commissionField,
  }
}

export const editUnitFormScheme = (
  t: TFunction,
  languageTabs: LanguageTabConfig[],
  buildings?: BuildingList,
) => {
  return Yup.object({
    ...commonValidationSchema(t),
    activity: Yup.boolean(),
    level: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .test(
        'level',
        t('common:validation_messages.building_doesnt_have_that_level'),
        function (value): boolean {
          const { building_id } = this.parent
          const buildingLevel = buildings?.data?.find(building => building.id === building_id)?.nol
          if (value && buildingLevel) {
            return value <= buildingLevel
          }
          return true
        },
      ),
    video_url: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
    hide_price: Yup.boolean(),
    building_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    ...generateTranslationSchema(languageTabs, t, true).fields,
  })
}

export const editUnitInlineFormScheme = (t: TFunction, building?: Building) => {
  return Yup.object({
    ...commonValidationSchema(t),
    level: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .test(
        'level',
        t('common:validation_messages.building_doesnt_have_that_level'),
        function (value): boolean {
          const buildingLevel = building?.nol
          if (value && buildingLevel) {
            return value <= buildingLevel
          }
          return true
        },
      ),
  })
}

export const editUnitFormDefault: EditUnitFormState = {
  has_ai_translation: false,
  translations: defaultTranslationsEdit,
  id: 0,
  activity: false,
  fee: 0,
  fee_local: 0,
  agent_fee: 0,
  agent_fee_local: 0,
  type: 0,
  level: 0,
  size: 0,
  net_size: 0,
  lotSize: 0,
  bedrooms: 0,
  bathrooms: 0,
  condition: null,
  video_url: '',
  hide_price: false,
  building_id: 0,
  prices: [],
  id_deleted_price: [],
  originalPrices: [],
  amenities: [],
  hn_hash: '',
  sn_hash: '',
}
